import { useState } from 'react';

const useModal = () => {
  const [isOpen,setisOpen] = useState(false)

	const handleToggle = () => {
    //show/ hide modal
		setisOpen(!isOpen)
  }

  
  return [
    isOpen,
    handleToggle
  ]
}


export default useModal;
