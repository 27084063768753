import React, { useState, useRef, useEffect } from "react";
import classes from "./Categories.module.scss";
import { getFileName } from "../../helpers/FileCaching";
import { TweenMax, Power3 } from "gsap";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { userService } from "../../services/userServices";
import SBTracking from "../../services/tracking";

import Spinner from "../Spinner/Spinner";

import { ReactComponent as SlashMenu } from "../../images/icons/slashMenu.svg";

const icons = require.context("../../images/icons", true, /.*\.(gif|png|jpe?g|svg)$/i);

const Categories = props => {
  const [indexSelected, setIndexSelected] = useState(null);

  //url variable
  let match = useRouteMatch();
  let uselocation = useLocation();
  let history = useHistory();
  //animation variables
  const elRef = useRef([]);
  //api variables
  const [loading, setLoading] = useState(true);
  const [siblingH, setSiblingH] = useState(false);

  //   const imagesArr = [];
  //   const imagesFinalArr = [];
  //   const categoryNames = [];
  //   const imagesFinalIDArr = [];
  const [userPhotoType, setUserPhotoType] = useState();

  const [data, setData] = useState(null);
  // constructor
  useEffect(() => {
    let userPhotoType = uselocation.state !== undefined ? uselocation.state.userType : "photographer";
    setUserPhotoType(userPhotoType);
    userService
      .getCategory(userPhotoType)
      .then(res => {
        setLoading(false);

        let data = res.data;

        let pageViewData = {
          page_category: "Categories",
          page_name: "App - Categories"
        };

        SBTracking.pageView(pageViewData);

        // data.map((val, index) => {
        //   let formattedApiName = val.name.toLowerCase().replace(/\s/g, "-");
        //   categoryNames.push(val.name);
        //   imagesFinalIDArr.push(val.id);
        // });

        setData(data);

        // setData(
        //   categoryNames.map((value, index) => (
        //     <li
        //       key={index}
        //       className={` siblingCatH ${classes.Categories__listItem}` + (siblingH ? " siblingOnH" : "")}>
        //       <Link
        //         className={` ${classes.Categories__listItemLink}`}
        //         to={{ pathname: `${match.path}/${imagesFinalIDArr[index]}`, state: { userType: userPhotoType } }}
        //         id={`cat-${index}`}
        //         onMouseEnter={changeBackground}
        //         onMouseLeave={changeBackgroundOut}
        //         onClick={() => getSelectedCategory(categoryNames[index])}>
        //         <h2 className={indexSelected && indexSelected !== index ? classes.categoryName : ""}>
        //           {categoryNames[index]}
        //         </h2>
        //       </Link>

        //       <span
        //         className={`
        //                             slashCat
        //                             ${classes.Categories__slashIcon}
        //                         `}
        //         style={{
        //           visibility: index !== imagesFinalIDArr.length - 1 ? "visible" : "hidden"
        //         }}>
        //         <SlashMenu />
        //       </span>
        //     </li>
        //   ))
        // );

        //animate svg
        TweenMax.set(elRef.current, { opacity: 0 });
        TweenMax.staggerTo(
          elRef.current,
          0.25,
          {
            opacity: 1,
            y: 0,
            ease: Power3.easeOut
          },
          0.15
        );
      })
      .catch(err => {
        console.log(err);
      });
  }, [uselocation.state]);

  function changeBackground(e, index) {
    setSiblingH(true);
    setIndexSelected(index);
  }

  function changeBackgroundOut(e) {
    setSiblingH(false);
    setIndexSelected(null);
  }

  function getSelectedCategory(name) {
    SBTracking.gtmTrack({
      event: "category_click",
      page_category: "Categories",
      page_name: "App - Categories",
      category_name: name
    });

    props.passCatName(name);

    SBTracking.SessionData.category = name;

    let pageViewData = {
      page_category: "Artists",
      page_name: "App - " + name
    };
    SBTracking.pageView(pageViewData);
  }

  const categoryNames = data?.map(x => x.name);
  const imagesFinalIDArr = data?.map(x => x.id);

  return (
    <div className={classes.Categories}>
      <div className={`container-fluid`}>
        <div className={` ${classes.Categories__wrap}`}>
          <div className={`row`}>
            {loading && <Spinner />}
            {data && (
              <ul className={`siblingContainer ${classes.Categories__listContainer}`}>
                {categoryNames.map((categoryName, index) => (
                  <li
                    key={index}
                    className={` siblingCatH ${classes.Categories__listItem}` + (siblingH ? " siblingOnH" : "")}>
                    <Link
                      className={` ${classes.Categories__listItemLink}`}
                      to={{ pathname: `${match.path}/${data[index].id}`, state: { userType: userPhotoType } }}
                      id={`cat-${index}`}
                      onMouseEnter={() => changeBackground(null, index)}
                      onMouseLeave={changeBackgroundOut}
                      onClick={() => getSelectedCategory(categoryName)}>
                      <h2
                        className={`${
                          (indexSelected || indexSelected === 0) && indexSelected !== index ? classes.deselected : ""
                        }`}>
                        {categoryName}
                      </h2>
                    </Link>

                    <span
                      className={` ${indexSelected || indexSelected === 0 ? "slashCat" : ""} ${
                        classes.Categories__slashIcon
                      }`}
                      style={{
                        visibility: index !== imagesFinalIDArr.length - 1 ? "visible" : "hidden"
                      }}>
                      <SlashMenu />
                    </span>
                  </li>
                ))}
                {/* {data } */}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
