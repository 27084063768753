import React, { useRef , useState, useEffect } from 'react';
import classes from './QuickSearch.module.scss';
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { userService } from '../../../services/userServices';
import {TweenMax, Power3} from 'gsap/gsap-core';
import SBTracking from '../../../services/tracking';

const QuickSearch = ({checkClicked, getTheSub, checkAvailableSub}) => {

    let uselocation = useLocation()
    let { id } = useParams();
    
    let userPhotoType = uselocation.state !== undefined ? uselocation.state.userType : 'photographer'

    const handlerSubmit = (e) => {
        e.preventDefault();
        clickDropdown();
        
        
    }


    let history = useHistory();
    const [hideDropdown, setHideDropdown] = useState(false);
  
    const redRef = useRef([]);
    const btn = useRef();
    const dropMenu = useRef();

    const [subcategories, setSubcategories] = useState([]);
    // let quickListItem ;
  useEffect(() => {
      
    userService.getCategory(userPhotoType)
    .then( (res) => {  
      let data = res.data;

      
      
      for (const property in data) {

          //check if the categories has subcategories
          if ( data[property].id === parseInt(id) &&  data[property].parent_flag === 1) {
            
           
            // set subcategories
           setSubcategories([data[property].child_category].map( value =>  (
                value.map( (v, i) =>  (
                    <li key={i} className={classes.QuickSearch__dropdownItem} onMouseOver={() => growOn(i)} onMouseOut={() => growOut(i)}>
                            <span ref={red => redRef.current[i] = red} className={classes.QuickSearch__onHover}></span>
                            <Link className={classes.QuickSearch__subCat} to={{
                                pathname: `/categories/${v.id}`,
                                state:  {userType: uselocation.state.userType } 
                            }}>
                                <h5 className={classes.QuickSearch__subCatItem} onClick={()=>checkClicked(SBTracking.SessionData.subCategory = v.name)}>
                                {(v.name.indexOf(' ') >= 0) ? v.name.split(' ')[1] : v.name}
                                </h5> 
                            </Link>
                        
                    </li>
                ))

            )))

          }
        }

      
    })
    .catch( err => {
          history.push("/login")
      }
    );


  },[])


    function setUpQuickSearch(){
        if(redRef.current != 0){
            TweenMax.set(redRef.current,{ width: 0, transformOrigin:"center right"});
        }
            
        if (btn.current != 0) {
            TweenMax.set(btn.current,{ color:'#ffffff'});
        }
    }

    function growOn(index){
        TweenMax.to(redRef.current[index], { width: 100, ease: Power3.easeOut});
    }
    function growOut(index){
        TweenMax.to(redRef.current[index], { width: 0, ease: Power3.easeOut});
    }

    function hoverInBtn(){
        TweenMax.to(btn.current, .3, {color: '#ff0037'});
    }

    function hoverOutBtn(){
        TweenMax.to(btn.current, .3, {color: '#ffffff'});
    }
   
    function clickDropdown(){
        if(!hideDropdown)setHideDropdown(true);
        else setHideDropdown(false)
    }

    useEffect(() => {
        setUpQuickSearch()
    }, []);

    return(
        <div className={classes.QuickSearch} >
            <button onClick={handlerSubmit} className={classes.QuickSearch__btn} onMouseOver={hoverInBtn} onMouseOut={hoverOutBtn}>
                <h5 ref={btn} className={classes.QuickSearch__title}>Quick Search</h5>
            </button>
            <div ref={dropMenu}  >
                <ul className={`${classes.QuickSearch__dropdown} ` + (hideDropdown ? `${classes.QuickSearch__activeDrop}` :  '') }>
                    {subcategories}
                </ul>
            </div>
          
            {/* {showDropdown  ?    <ul ref={btn} className={`${classes.QuickSearch__dropdown}` + (checkClick ? ' showSearch' : '')}>
                                    {quickListItem}
                                </ul>
                            : null                        
            } */}
       
        </div>
    )
}

export default QuickSearch;