import React, { useRef, useState, useEffect } from "react";
import classes from "./Hero.module.scss";
import { getFileName } from "../../helpers/FileCaching";
import { userService } from "../../services/userServices";
import { Link, useParams } from "react-router-dom";
import { TweenMax, TweenLite, Power3, Cubic } from "gsap/gsap-core";
const titleImages = require.context("../../images/Hero/Title", true, /.*\.(gif|png|jpe?g|svg)$/i);

const Hero = props => {
  const heroC = useRef();
  const iPadMediaQ = window.matchMedia("(min-width: 1200px)");

  const singleTitleInd1 = 4;
  let singleTitle1 = "title_0.svg";
  const titleArr1 = [];
  const title1Ref = useRef([]);

  const singleTitleInd2 = 8;
  let singleTitle2 = "title_0.svg";
  const titleArr2 = [];
  const title2Ref = useRef([]);

  const singleTitleInd3 = 13;
  let singleTitle3 = "title_0.svg";
  const titleArr3 = [];
  const title3Ref = useRef([]);

  const singleTitleInd4 = 14;
  let singleTitle4 = "title_0.svg";
  const titleArr4 = [];
  const title4Ref = useRef([]);

  const singleTitleInd5 = 19;
  let singleTitle5 = "title_0.svg";
  const titleArr5 = [];
  const title5Ref = useRef();
  const title6Ref = useRef();
  const title7Ref = useRef();

  const elms = [
    title1Ref.current,
    title2Ref.current,
    title3Ref.current,
    title4Ref.current,
    title5Ref.current,
    title6Ref.current,
    title7Ref.current
  ];

  const [directorsAccess, setDirectorsAccess] = useState(localStorage.getItem("directors_access"));
  const [photographersAccess, setPhotographersAccess] = useState(localStorage.getItem("photographers_access"));

  for (let i = 0; i < singleTitleInd1; i++) {
    singleTitle1 = `title_${i}.svg`;
    titleArr1.push(singleTitle1);
  }
  for (let i = 3; i < singleTitleInd2; i++) {
    singleTitle2 = `title_${i}.svg`;
    titleArr2.push(singleTitle2);
  }

  for (let i = 7; i < singleTitleInd3; i++) {
    singleTitle3 = `title_${i}.svg`;
    titleArr3.push(singleTitle3);
  }

  for (let i = 12; i < singleTitleInd4; i++) {
    singleTitle4 = `title_${i}.svg`;
    titleArr4.push(singleTitle4);
  }

  for (let i = 16; i < singleTitleInd5; i++) {
    singleTitle5 = `title_${i}.svg`;
    titleArr5.push(singleTitle5);
  }

  let images1 = titleArr1.map((image, index) => {
    return (
      <div key={index} className={`${classes.Hero__titleImgContainer}`}>
        <div
          ref={el => (title1Ref.current[index] = el)}
          className={classes.Hero__titleImg}
          id={`title1Svg_${index}`}
          style={{
            backgroundImage: "url(" + getFileName(titleImages, image) + ")"
          }}></div>
      </div>
    );
  });

  let images2 = titleArr2.map((image, index) => {
    return (
      <div key={index} className={`${classes.Hero__titleImgContainer}`}>
        <div
          ref={el => (title2Ref.current[index] = el)}
          className={`${classes.Hero__titleImg}`}
          id={`title2Svg_${index}`}
          style={{
            backgroundImage: "url(" + getFileName(titleImages, image) + ")"
          }}></div>
      </div>
    );
  });

  let images3 = titleArr3.map((image, index) => {
    return (
      <div key={index} className={`${classes.Hero__titleImgContainer}`}>
        <div
          ref={el => (title3Ref.current[index] = el)}
          className={`${classes.Hero__titleImg}`}
          id={`title3Svg_${index}`}
          style={{
            backgroundImage: "url(" + getFileName(titleImages, image) + ")"
          }}></div>
      </div>
    );
  });

  let images4 = titleArr4.map((image, index) => {
    return (
      <div key={index} className={`${classes.Hero__titleImgContainer}`}>
        <div
          key={index}
          ref={el => (title4Ref.current[index] = el)}
          className={`${classes.Hero__titleImg}`}
          id={`title4Svg_${index}`}
          style={{
            backgroundImage: "url(" + getFileName(titleImages, image) + ")"
          }}></div>
      </div>
    );
  });

  useEffect(() => {
    TweenMax.set(
      [
        title1Ref.current,
        title2Ref.current,
        title3Ref.current,
        title4Ref.current,
        title5Ref.current,
        title6Ref.current,
        title7Ref.current
      ],
      { opacity: 0, y: -5 }
    );

    if (iPadMediaQ.matches) {
      elms.sort(function () {
        return 0.5 - Math.random();
      });
    }

    TweenMax.staggerTo(
      [
        title1Ref.current,
        title2Ref.current,
        title3Ref.current,
        title4Ref.current,
        title5Ref.current,
        title6Ref.current,
        title7Ref.current
      ],
      0.25,
      { opacity: 1, y: 0, ease: Power3.easeOut },
      0.1
    );
  }, [iPadMediaQ.matches]);

  return (
    //title container  3/5 not needed
    <div className={classes.Hero} ref={heroC}>
      <div className={`row ${classes.Hero__innerContainer}`}>
        <div className={`col-12 titleContainer1`}>{images1}</div>
        <div className={`col-12 titleContainer2`}>
          {images2}
          {images3}
          {images4}
          {photographersAccess === "1" ? (
            <>
              <Link
                className={classes.Hero__titleLink}
                ref={title5Ref}
                to={{
                  pathname: "/categories",
                  state: { userType: "photographer" }
                }}>
                <div className={`${classes.Hero__titleImgContainerLink}`}>
                  <div className={`${classes.Hero__titleImgHoverLink}`}>
                    <div
                      className={`${classes.Hero__titleImg}`}
                      id={"title5Svg_0"}
                      style={{
                        backgroundImage: "url(" + getFileName(titleImages, "title_16.svg") + ")"
                      }}></div>
                  </div>
                </div>
              </Link>
              <div className={`${classes.Hero__titleImgContainer}`}>
                <div
                  ref={title6Ref}
                  className={`${classes.Hero__titleImg}`}
                  id={"title5Svg_1"}
                  style={{
                    backgroundImage: "url(" + getFileName(titleImages, "title_17.svg") + ")",
                    visibility: directorsAccess === "1" ? "visible" : "hidden"
                  }}></div>
              </div>
            </>
          ) : (
            ""
          )}

          <Link
            className={classes.Hero__titleLink}
            ref={title7Ref}
            to={{
              pathname: "/categories",
              state: { userType: "director" }
            }}
            style={{
              visibility: directorsAccess === "1" ? "visible" : "hidden"
            }}>
            <div className={`${classes.Hero__titleImgContainerLink}`}>
              <div className={`${classes.Hero__titleImgHoverLink}  ${classes.Hero__titleImgHoverLinkDirector}`}>
                <div
                  className={`${classes.Hero__titleImg}`}
                  id={"title5Svg_2"}
                  style={{
                    backgroundImage: "url(" + getFileName(titleImages, "title_18.svg") + ")",
                    visibility: directorsAccess === "1" ? "visible" : "hidden"
                  }}></div>
              </div>
            </div>
          </Link>
        </div>
        {/* <div className={`col-12 titleContainer5`}></div> */}
      </div>
    </div>
  );
};

export default Hero;
