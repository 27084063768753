import axiosConfig from '../axiosConfig'


export function createLightbox(name, mediaAssetId){
  return  axiosConfig.post(`lightbox/create`, {
      name: name,
      media_asset_id: mediaAssetId
  })    
}

export function getLighboxes(){    
  return axiosConfig.get(`lightbox/list`)    
}

export function getLightboxebyID(id){
  return axiosConfig.post(`lightbox/show`, {
      lightbox_id: id
  })    
}

export function addToLightbox( mediaAssetId, lightboxId){
  return  axiosConfig.post(`lightbox/media-asset/add`, {
      media_asset_id: mediaAssetId,
      lightbox_id: lightboxId,
  })    
}

export function deleteLightboxMediaAsset( mediaAssetId, lightboxId){
  return  axiosConfig.post(`lightbox/media-asset/delete`, {
      lightbox_id: lightboxId,
      media_asset_id: mediaAssetId,
  })    
}

export function deleteLightbox(lightboxId){
  return  axiosConfig.post(`lightbox/delete`, {        
      lightbox_id: lightboxId
  })    
}



export function getLighboxeShareLink(lightboxShareLink){    
  return axiosConfig.get(`lightbox/share-public/${lightboxShareLink}`)    
}
