import React from "react";
import { getFileName } from '../../../../helpers/FileCaching';
import classes from "./PhotoCollectionItem.module.scss";

const icons = require.context( '../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);
const playIcon = getFileName(icons, 'play-button.svg');

const PhotoCollectionItem = ({showCarouselCallback, lightboxCheckCallback, content, imagesRef, collectionIndex}) => {

    return (
        <div ref={el => imagesRef.current[collectionIndex] = el} data-mediaassetid={content.id} className={`siblingImgH ${classes.PhotoCollectionItem__photoItem }`} >

         { (content.url_path).indexOf('.mp4') > 0
         ?
         <>
           {/* <div className="checkbox-container">
             <label className="checkbox-label">
               <input type="checkbox" onChange={lightboxCheckCallback} data-mediaassetid={content.id} className={classes.PhotoCollectionItem__addLightbox__checkbox} />
               <span className="checkbox-custom rectangular"></span>
             </label>
           </div>               */}
           
           
           <video height='100%' width='100%' playsInline className={classes.PhotoCollectionItem__photo}>
             <source src={`${content.url_path}#t=9`} type="video/mp4" />
           </video>
                             
           <img className={classes.PhotoCollectionItem__video_play_icon} src={playIcon} alt=""  onClick={ () => showCarouselCallback(content, collectionIndex)}/>
         </>
         :
         <>
           
           <div className = {` ${classes.PhotoCollectionItem__photo} ` }
               style = {{
                 backgroundImage: 'url(' + content.url_l_path + ')'
               }}
               onClick={ () => showCarouselCallback(content, collectionIndex)}>
             
           </div>
      
           {/* <div className="checkbox-container">
               <label className="checkbox-label">
                   <input type="checkbox" onChange={lightboxCheckCallback} data-mediaassetid={content.id} className={classes.PhotoCollectionItem__addLightbox__checkbox} />
                   <span className="checkbox-custom rectangular"></span>
               </label>
           </div> */}
             
         </>
         }
       </div> 
     )
};

export default PhotoCollectionItem;
