import React from "react";
import artistShowcase from "./ArtistShowcase.module.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";

/**
 *
 * @param content {{artistName: string; artistId: number; description: string; image: {src: string; categoryId: number; categoryName: string;}; }[]}
 */
export const ArtistShowcase = ({ content }) => {
  return (
    <div className={artistShowcase.wrapper}>
      <div style={{ width: "90%", margin: "0 auto" }}>
        <h2 style={{ fontSize: "8vh" }}>NEWS</h2>
      </div>
      <Showcase>
        {content.slice(0, 3).map((artist, index) => {
          const { artistName, artistId, image, description } = artist;
          return (
            <Link
              className={`${artistShowcase.link} ${artistShowcase.showcase}`}
              key={index}
              href="https://example.com"
              // to={{ pathname: `/categories/${image.categoryId}/${artistId}`, state: { userType: "photographer" } }}>
              // to={{ href: "https://example.com" }}
            >
              <img className={`${artistShowcase.image}`} key={index} src={image.src} alt={artistName} />
              <div className={`${artistShowcase.cover}`} />
              <div className={`${artistShowcase.artistShowcase} ${artistShowcase.showcase} `}>
                <h4 className={`${artistShowcase.artistShowcaseCategory}`}>{image.categoryName}</h4>
                <h2 className={`${artistShowcase.artistShowcaseName}`}>{artistName}</h2>
                <p className={`${artistShowcase.description}`} dangerouslySetInnerHTML={{ __html: description }} />
                <h2 className={`${artistShowcase.artistShowcaseLink}`}>SEE MORE</h2>
              </div>
            </Link>
          );
        })}
      </Showcase>
    </div>
  );
};

const Showcase = ({ children }) => {
  const [child1, child2, child3] = children;

  return (
    <div className={`${artistShowcase.template}`}>
      {child1 && <div className={artistShowcase.a}>{child1}</div>}
      {child2 && <div className={artistShowcase.b}>{child2}</div>}
      {child3 && <div className={artistShowcase.c}>{child3}</div>}
    </div>
  );
};
