import React, { useState, useEffect } from "react";
import classes from "./ModalConfirmation.module.scss";
import { getFileName } from '../../../../../helpers/FileCaching';
import { useLocation } from 'react-router-dom';
const icons = require.context('../../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);


const ModalConfirmation = ({close, mediaAssetId, lightboxesId, dellIMg, lightboxesPARId, deleteLightbox}) => {
  let url = useLocation();

  const [checkDeleteImg, setCheckDeleteImg] = useState();

  //confirm delete image in lightbox
  const handleDeleteImg = (mediaAssetId, lightboxesId) => {
    dellIMg(mediaAssetId, lightboxesId);
    setTimeout(function () {
      close()
    }, 1000);

  }

  //confirm delete entire lightbox
  const handleDeleteLightbox = (lightboxesPARId) => {
    deleteLightbox(lightboxesPARId);
    setTimeout(function () {
      close()
    }, 1000);
   
  }

  useEffect(() => {
    if (url.pathname === '/lightboxes'){
      setCheckDeleteImg(false)

    }else{
      setCheckDeleteImg(true)
    }
  }, []);


  return (
    <div  className={classes.ModalConfirmation}>
        <div className={`${classes.ModalConfirmation__modalContent}`}>          
          <div className={classes.ModalConfirmation__body}>
            <div className={classes.lightbox}>
              <div className={classes.ModalConfirmation__wrapper}>
                <h5 className={classes.ModalConfirmation__title}>Are you sure?</h5>
                {checkDeleteImg ? <p className={classes.ModalConfirmation__message}>Do you really want to delete this image? This process cannot be undone.</p>
                  : <p className={classes.ModalConfirmation__message}>Do you really want to delete this vault? This process cannot be undone.</p>
                }
               
                <div className={classes.ModalConfirmation__btnContainer}>
                  <button type="button" className={`${classes.ModalConfirmation__confirmBtn} ${classes.ModalConfirmation__cancelbtn}`} onClick={close}>Cancel</button>

                {checkDeleteImg ? <button type="button" className={`${classes.ModalConfirmation__confirmBtn} ${classes.ModalConfirmation__deletebtn}`} onClick={() => handleDeleteImg(mediaAssetId, lightboxesId)}>Delete</button>
                  :  <button type="button" className={`${classes.ModalConfirmation__confirmBtn} ${classes.ModalConfirmation__deletebtn}`} onClick={() => handleDeleteLightbox(lightboxesPARId)}>Delete</button>
                }
                </div>
              </div>
          
            <button onClick={close} className={classes.lightbox__close}>
              <div  className={classes.lightbox__icon } 
                style={
                    {
                      backgroundImage: 'url(' + getFileName(icons, 'close_lightbox.svg') + ')'
                    }
                  }>
              </div>
            </button>
          </div>

          </div>
        </div>
      </div>
     
  );
};

export default ModalConfirmation;
