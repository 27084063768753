import React, { useRef, useState, useEffect, useCallback } from "react";
import classes from "./PhotoUsers.module.scss";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { userService } from "../../services/userServices";
import { shuffle } from "../../helpers/Shuffle";
import { getFileName } from "../../helpers/FileCaching";
import { TweenMax, Power3 } from "gsap/gsap-core";
import content from "../../data/content.json";
import { Link, useRouteMatch } from "react-router-dom";
import QuickSearch from "../PhotoUsers/QuickSearch/QuickSearch";
import Spinner from "../Spinner/Spinner";

import SBTracking from "../../services/tracking";

const usersImages = require.context("../../images/Users", true, /.*\.(gif|png|jpe?g|svg)$/i);

// const PhotoUsers = (checkClicked, props) => {
const PhotoUsers = props => {
  // const heightSM = window.matchMedia("(min-height: 768px)");
  // const heightMD = window.matchMedia("(min-height: 992px)");
  // const heightLG = window.matchMedia("(min-height: 1200px)");

  let uselocation = useLocation();

  const [loading, setLoading] = useState(true);
  let history = useHistory();
  // get params from url
  let { id } = useParams();

  let match = useRouteMatch();

  const listRef = useRef([]);
  const allRef = useRef();

  const [isOver, setIsOver] = useState(false);
  const [hoverImg, setHoverImg] = useState("reset.png");

  const [siblingH, setSiblingH] = useState(false);
  const [limitUserLength, setlimitUserLength] = useState(false);
  const imgAnim = useRef();

  const [isClicked, setIsClicked] = useState(false);
  // const [subCatId, setSubCatId] = useState();

  const [getCategoryByPhotouser, setgetCategoryByPhotouser] = useState([]);
  const [haveSubcategories, setHaveSubcategories] = useState(false);

  let userPhotoType = uselocation.state !== undefined ? uselocation.state.userType : "photographer";
  useEffect(() => {
    userService
      .getCategory(userPhotoType)
      .then(res => {
        let data = res.data;

        for (const property in data) {
          //check if the categories has subcategories
          if (data[property].id === parseInt(id) && data[property].parent_flag === 1) {
            setHaveSubcategories(true);
          }
        }
      })
      .catch(err => {
        userService.logout();
        history.push("/login");
      });
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    userService
      .getPhotoUser(userPhotoType, id)
      .then(res => {
        let data = res.data;
        setLoading(false);

        let shuffledItems = shuffle(Object.entries(data));

        if (loading) {
          const getCategoryByPhotoUserArray = shuffledItems.map((value, index) => {
            // console.log(value)
            value[1].position = index + 1;
            return value;
          });

          if (getCategoryByPhotoUserArray.length > 14) {
            setlimitUserLength(true);
          }

          // set state

          //Data for artist tracking
          let impressions = [];

          getCategoryByPhotoUserArray.forEach((element, index) => {
            let artistData = element[1];
            impressions.push({
              name: artistData.first_name + " " + artistData.last_name,
              id: String(artistData.id), //Photographer ID
              price: "00.01", //Photographer License Price
              brand: "N/A //change to API required",
              category: artistData.category
                .map((value, index) => {
                  return value.name;
                })
                .toString(),
              variant: "",
              list: "Category",
              position: index + 1
            });
          });

          let data = {
            event: "artist_impression",
            ecommerce: {
              currencyCode: "GBP",
              impressions: impressions
            }
          };
          SBTracking.gtmTrack(data);

          setData(getCategoryByPhotoUserArray);

          TweenMax.staggerFromTo(
            listRef.current,
            0.25,
            { opacity: 0 },
            {
              opacity: 1,
              y: 0,
              ease: Power3.easeOut
            },
            0.15
          );
        }
      })
      .catch(err => {
        userService.logout();
        history.push("/login");
      });
  }, [match.url]);

  const checkChildState = useCallback(() => {
    if (!isClicked) {
      SBTracking.gtmTrack({
        event: "quick_search",
        page_category: "Category",
        page_name: "App - Category - " + props.catName,
        quick_search_option: SBTracking.SessionData.subCategory //Location, Studio
      });
    }
    changeOpacity();
  });

  function changeOpacity() {
    if (!isClicked) setIsClicked(true);
    else setIsClicked(false);
  }

  function gtagClick(value) {
    // SBTracking.trackEvent("View Photographer", value[1].first_name+" "+value[1].last_name)

    let data = {
      artist_name: value[1].first_name + " " + value[1].last_name,
      artist_category: props.catName,
      artist_id: value[1].id,
      position: value[1].position
    };
    SBTracking.SessionData.Category = props.catName;
    SBTracking.artistClick(data);
  }

  const [indexHovered, setIndexHovered] = useState(null);

  function changeBackground(index) {
    setSiblingH(true);
    setIndexHovered(index);
  }

  function changeBackgroundOut() {
    setSiblingH(false);
    setIndexHovered(null);
  }

  function outCategory(index) {
    setIsOver(false);
    setHoverImg("reset.png");
  }

  return (
    <div className={classes.PhotoUsers}>
      <div className={`container-fluid ${classes.PhotoUsers__wrap}`}>
        {loading ? (
          <Spinner />
        ) : (
          <div ref={allRef} className={classes.PhotoUsers__List}>
            {isOver ? (
              <div
                ref={imgAnim}
                className={`previewImg`}
                style={{
                  backgroundImage: "url(" + getFileName(usersImages, hoverImg) + ")"
                }}></div>
            ) : null}

            <div className={` ${classes.PhotoUsers__listWrap}`}>
              <div className={`row`}>
                <div className={`col-md-12 col-xl-8 p-0 ${classes.PhotoUsers__header}`}>
                  <div className={`col-md-6 p-0 ${classes.PhotoUsers__header_wrap}`}>
                    <h5 className={classes.PhotoUsers__title}>{props.catName}</h5>
                  </div>
                  {/* <div className={`col-md-5 p-0 `}> */}

                  {/* <QuickSearch getIdClicked checkClicked={checkChildState} getTheSub={checkSubCatClicked}/> */}
                  {haveSubcategories ? <QuickSearch checkClicked={checkChildState} /> : null}
                  {/* </div> */}
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-12 p-0` + (isClicked ? " addOpacity" : "")}>
                  <div className={` ${classes.PhotoUsers__mainUsersTitle}`}>
                    <div className={classes.PhotoUsers__listContainer}>
                      {loading ? (
                        <div className={`loader`}></div>
                      ) : (
                        <ul
                          className={
                            `siblingContainer ${classes.PhotoUsers__list}` +
                            (limitUserLength ? `${classes.PhotoUsers__photoUserColumn}` : "")
                          }>
                          {data.map((value, index) => (
                            <li
                              key={index}
                              ref={el => (listRef.current[index] = el)}
                              className={
                                `siblingCatH  ${classes.PhotoUsers__listItem}` + (siblingH ? " siblingOnH" : "")
                              }>
                              <Link
                                className={classes.PhotoUsers__listItemLink}
                                to={{
                                  pathname: `${match.url}/${value[1].id}`,
                                  state: { userType: uselocation.state.userType }
                                }}
                                onMouseEnter={() => changeBackground(index)}
                                onMouseLeave={changeBackgroundOut}
                                onClick={() => gtagClick(value)}>
                                <h2
                                  className={`${classes.PhotoUsers__listItemTitle} ${
                                    indexHovered !== null && index !== indexHovered ? classes.deselect : ""
                                  }`}>
                                  {" "}
                                  {value[1].first_name} {value[1].last_name !== "#" ? value[1].last_name : ""}
                                </h2>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoUsers;
