import axiosConfig from '../axiosConfig'

//get userLogo
export  function getHeaderLogo(){
    return axiosConfig.get(`/agency/info`)
}

// get all categories
export function getCategory( photoUserType ) {
  return axiosConfig.post(`/agency/categories/access`, { photoUserType })
}

//get photographer by category
export function getPhotoUser(photoUserType, category_id ){
  return axiosConfig.post(`/agency/photo-users/category`, {photoUserType, category_id} )
}

//get photographer by category
export function getPhotoCollection( category_id, photo_user_id ){
    category_id = Number(category_id)
    photo_user_id = Number(photo_user_id)
  return axiosConfig.post(`/agency/categories/photo-user`, {category_id, photo_user_id} )
}
