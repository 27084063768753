import React, {useRef, useEffect, useState } from "react";
import classes from "./CreateUpdateModal.module.scss";
import { userService } from '../../../../../services/userServices';
import {TweenMax, Power3} from 'gsap/gsap-core';
import { getFileName } from '../../../../../helpers/FileCaching';
import  useModal from '../../../../../share/useModal';

import SBTracking from "../../../../../services/tracking"



const icons = require.context('../../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);


const CreateUpdateModal = ({ selectedMediaAssetId, resetselectedMediaAssetId, close}) => {

  const [isOpen,handleToggle] = useModal(false);
  const [addExistingLightboxdropdown, setaddExistingLightboxdropdown] = useState();
  const [addLightboxIDDropdown, setAddLightboxIDDropdown] = useState();
  const [changeLabel, setChangeLabel] = useState(`Choose the vault`);


  const [showCreateBtn, setShowCreateBtn] = useState(false)
  const [showAddBtn, setShowAddBtn] = useState(false)
  const [showAddedMessage, setShowAddedMessage] = useState();
  const [hideDropdown, setHideDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const actionTitle = useRef();

  const handleSubmit = close => (e) => {
    e.preventDefault();

    //DO Tracking here 

   

    //variables
    const name = e.target[0].value
    
    try {

      //create lightbox
      userService.createLightbox(name).then( res => {
        SBTracking.gtmTrack({
          'event': 'vault_checkout_option',
          'ecommerce': {
            'checkout_option': {
              'actionField': {'step': 1, 'option': 'Create new Vault'}
            }
          }
        })
        //show error
        if ( res.data.status === "error" ){

          setErrorMessage(res.data.message)
          return
        }
        let lightboxID = res.data.data.id

       
        
        if ( res.data.status === "success") {
          //add images to the new lightbox
          SBTracking.SessionData.newLightboxID = String(res.data.data.id);

          SBTracking.gtmTrack({
            'event': 'vault_options',
            'option_type':'Create new Vault',
            'vault_name':res.data.data.name,
            'vault_id':String(res.data.data.id)
          })

          let addImage = new Promise((resolve,reject) =>{
  
            let imageCount = 0;
    
            //DO the api calls
            selectedMediaAssetId.map( async MediaAssetid => {
              await userService.addToLightbox(MediaAssetid, lightboxID).then(res =>{
                
              }).catch(error =>{
                reject()
              })
              //When each api call is successful increase the load count variable
                imageCount++
                actionMessage();
                resetselectedMediaAssetId()
                setTimeout( () =>  {
                  close()
                }, 2500);
                //When load count veriable is matching the amount of images,  resolve 
                if (imageCount == selectedMediaAssetId.length){
                  resolve(true)
                }
            })
          })
          //All have been sucsessful,  resolve
          addImage.then(result => {
            SBTracking.gtmTrack({
              'event': 'image_added_to_vault',
              'ecommerce': {
                'purchase': {
                  'actionField': {
                    'id': SBTracking.SessionData.newLightboxID+'-'+SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].id+'-'+Date.now(),
                    'affiliation': 'App Squint Box',
                    'revenue': '00.01'
                  },
                  'products': getTrackingWorkProducts()
                }
              }
            })
          }).catch(error =>{
          })

        }
      })
    } catch (error) {
      throw new Error()
    }
  }

  function getTrackingWorkProducts(){
    let workProducts = []
    selectedMediaAssetId.forEach(workId => {
      workProducts.push({
        'name': SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].name,
        'id': SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].id,
        'price': '00.01',
        'brand': SBTracking.SessionData.artistBrand,
        'category': SBTracking.SessionData.category,
        'variant': String(workId), //artist work id
        'quantity': 1
        })
    });
    return workProducts
  }

  useEffect(() =>{

    SBTracking.gtmTrack({
      'event': 'add_to_vault',
      'ecommerce': {
        'currencyCode': 'GBP',
        'add': {
          'products': getTrackingWorkProducts()
        }
      }
    })
    SBTracking.gtmTrack({
        'event': 'vault_checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {'step': 1, 'option': 'Vault options loaded'},
            'products': getTrackingWorkProducts()
         }
       }
    })
  },[])

  const handleAddsubmit = close => (e) => {
    
    
    e.preventDefault();
    //params

    try{

      let addImage = new Promise((resolve,reject) =>{
  
        let imageCount = 0;

        //DO the api calls
        selectedMediaAssetId.map( async MediaAssetid => {
          await userService.addToLightbox(MediaAssetid, addLightboxIDDropdown).then(res =>{
            SBTracking.SessionData.addLightboxID = String(res.data.lightbox_id);
          }).catch(error =>{
            reject()
          })
          //When each api call is successful increase the load count variable
            imageCount++
            actionMessage();
            resetselectedMediaAssetId()
            setTimeout( () =>  {
              close()
            }, 2500);
            //When load count veriable is matching the amount of images,  resolve 
            if (imageCount == selectedMediaAssetId.length){
              resolve(true)
            }
        })
      })
       //All have been sucsessful,  resolve
      addImage.then(result => {
        SBTracking.gtmTrack({
          'event': 'image_added_to_vault',
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': SBTracking.SessionData.addLightboxID+'-'+SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].id+'-'+Date.now(),
                'affiliation': 'App Squint Box',
                'revenue': '00.01'
              },
              'products': getTrackingWorkProducts()
            }
          }
        })
      }).catch(error =>{
      })
      
        

    }catch(e){
      throw new Error()
    }
    
  }


  //toggle create icon on change input
  const showBtn = (e) => {
    (e.target.value.length === 0) ? setShowCreateBtn(false) :setShowCreateBtn(true)
  }

  const onAddExistingLight = (val) => {
    //close the menu
    handleToggle()
    //change label for selected option
    setChangeLabel(val.name);
    //get lightbox id from selected option
    setAddLightboxIDDropdown(val.id)
    //show button 
    setShowAddBtn(true)

    SBTracking.gtmTrack({
      'event': 'vault_checkout_option',
      'ecommerce': {
        'checkout_option': {
          'actionField': {'step': 1, 'option': 'Add to existing vault'}
        }
      }
    })

    SBTracking.gtmTrack({
      'event': 'vault_options',
      'option_type':'Add to existing vault',
      'vault_name':val.name,
      'vault_id':String(val.id)
    })
  }


  ///show message when created or added to lightbox
  const actionMessage = (e) =>{
    
      const actionMsg = (<div className={`col-md-12 ${classes.Modal__actionMessage}`}>
                            <h5 ref={actionTitle} className={classes.Modal__actionTitle}> { e ? e : `Image added to the vault`}</h5>
                          </div>);

    setHideDropdown(true)
    setShowAddedMessage(actionMsg)
     
  } 

  //constructor
  useEffect(() =>{
   // fetch lightbox list
   userService.getLighboxes().then( (res) => {
    setaddExistingLightboxdropdown(res.data)
   })

    //not sure about this code
    if(actionTitle.current){
      TweenMax.fromTo(actionTitle.current, .5,{opacity:0, scale:0}, {delay:0, scale:1, opacity:1,  ease: Power3.easeOut});
    }
    
  }, []);

  return (
    <div  className={classes.Modal}>
        <div className={`${classes.Modal__modalContent}`}>
        
          <div className={classes.Modal__body}>
     
            <div className={`col-md-12 ${classes.lightbox}`}>
              {hideDropdown === false
                ? 
                 <div>
                    <p className={classes.Modal__title}>Create new Vault</p>
                    <form onSubmit={ handleSubmit(close)}>
                      <div className={classes.lightbox__formGroup}>
                        <div className="d-flex justify-content-between align-content-end">
                          <input type="text" placeholder="enter new vault name" className={classes.lightbox__formGroupInput} name="name" onChange={showBtn}/>
                          { showCreateBtn && <input type="submit" value="" className={`${classes.lightbox__icon} ${classes.lightbox__create}`} style={{background: 'url(' + getFileName(icons, 'create_lightbox.svg') + ')'}}/> }
                        </div>
                        <p className={classes.Modal__title}>{ errorMessage }</p>
                      </div>
                    </form>
                  <p className={classes.Modal__title}>Add to existing Vault</p>               
                  <form onSubmit={ handleAddsubmit(close)}>

                  <div className={`${classes.lightbox__dropdownContainer}  ` } >

                    <label htmlFor="openDropdown" name="lightbox__list" id="lightbox__list" className={`${classes.lightbox__dropdown} ` + ( isOpen ? ` ${classes.lightbox__dropdownLabelFocus}` :'' )} onClick={handleToggle}>
                      {changeLabel}
                      <div  className={classes.lightbox__arrow + (isOpen ? ` ${classes.lightbox__arrowOpen}` : '')} 
                        style={{ backgroundImage: 'url(' + getFileName(icons, 'down.svg') + ')' }}>
                      </div>
                    </label>
                
                    <input type="checkbox" id="openDropdown" className={`${classes.lightbox__openDropdown}`} hidden />

                      <div className={classes.lightbox__dropdownMenu  + (isOpen ? ` ${classes.lightbox__openedDrDown}` : ' d-none')}>
                      
                        { addExistingLightboxdropdown && addExistingLightboxdropdown.map( (val, index) => {
                          return <span key={index}  onClick={ () => onAddExistingLight(val)}> {val.name} </span>
                        })}

                      </div>

                  {showAddBtn && <input type="submit" value="" className={`${classes.lightbox__icon} ${classes.lightbox__add}`} style={{background: 'url(' + getFileName(icons, 'add_lightbox.svg') + ')'}} /> }
                </div>
              </form>
            
                <button onClick={close} className={classes.lightbox__close}>
                  <div  className={classes.lightbox__icon } 
                    style={
                      {
                        backgroundImage: 'url(' + getFileName(icons, 'close_lightbox.svg') + ')'
                      }
                    }>
                  </div>
                </button>
                </div>
                  : showAddedMessage
              }
            </div>
          

        

          </div>
        </div>
      </div>
     
  );
};

export default CreateUpdateModal;
