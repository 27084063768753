import React, {useRef, useState, useEffect} from "react";
import Slider from "react-slick";
import { getFileName } from '../../../../helpers/FileCaching';
import { useLocation } from 'react-router-dom';
import classes from "./Carousel.module.scss";
import "./SlickSlideOverride.scss";

const icons = require.context('../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);

/////// CAROUSEL CUSTOM ARROWS 
function PrevArrow(props) {
  const { className, style, onClick } = props;
  const prev = getFileName(icons, 'arrow.svg');
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: 'rotate(180deg)',  backgroundImage: "url("+prev+")"}}
      onClick={onClick}
    />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  const next = getFileName(icons, 'arrow.svg');
  return (
    <div
      className={className}
      style={{ ...style, display: "block",   backgroundImage: "url("+next+")" }}
      onClick={onClick}
    />
  );
}



const PhotosCarousel = ({ photos, photoItem, photosDetails, onPhotoChangeCallback}) => {
  let url = useLocation();
  let carouselReferences = useRef([]);
  const [checkLightbox, setCheckLightbox] = useState(false);
  const firstSlide = photoItem;
  const settings = {
      fade: true,
      infinite: true,
      speed: 500,
      centerMode: true,
      centerPadding: '0',
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      swipeToSlide: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      initialSlide: firstSlide,
      beforeChange:onNextSlide,
      className: "photoSlider"
  };

  useEffect(() => {
    if(onPhotoChangeCallback)
      onPhotoChangeCallback(photos[photoItem], (photosDetails ? photosDetails[photoItem] : null))
  },[photoItem])

  useEffect(() => {
    let currentVideo = carouselReferences.current[firstSlide].getElementsByTagName("VIDEO")

    if(currentVideo.length > 0){
      currentVideo[0].play();
      currentVideo[0].volume = 0.2;
    }

  },[])

  useEffect(() => {
   
    if (url.pathname.indexOf("lightboxes") > -1 || url.pathname.indexOf("lightbox") > -1) {
      setCheckLightbox(true)

    } else {
     
      setCheckLightbox(false)
    }
  }, []);

  function onNextSlide(oldIndex, newIndex){
    onPhotoChangeCallback(photos[newIndex], (photosDetails ? photosDetails[newIndex] : null))

    let previousVideoTag = carouselReferences.current[oldIndex].getElementsByTagName("VIDEO")
    let nextVideoTag = carouselReferences.current[newIndex].getElementsByTagName("VIDEO")

    if(previousVideoTag.length > 0){
      previousVideoTag[0].pause()
    }

    if(nextVideoTag.length > 0){
      nextVideoTag[0].play()
    }

  }

  return (

    <div  className={classes.PhotosCarousel}>
      <Slider {...settings} >
    
        {photos.map((value, index) => (
          
          <div ref={(ref) => { carouselReferences.current[index] = ref }} className={classes.PhotosCarousel__photo}  key={index} >

              { (value.url_path).indexOf('.mp4') > 0 
                ?
               <div>
                  <video key={index}  className={`img-fluid ${classes.PhotosCarousel__photo}`} src={value.url_path} controls></video>
                    {checkLightbox ? <div className={classes.PhotosCarousel__lightboxDetails}>
                                    <p>
                                      <span>{photosDetails[index][0]} </span>
                                      <span>{photosDetails[index][1]}</span>
                                      <span>{`, ${photosDetails[index][2]} `}</span> 
                                    </p>
                                  
                                  </div>
                   : ''}
                 </div>
                :
                <div>
                  <img key={index}  className={`img-fluid ${classes.PhotosCarousel__photo}`} src={value.url_path} alt={`image_${index}`} />
          
                    {checkLightbox ? <div className={classes.PhotosCarousel__lightboxDetails}>
                                    <p>
                                      <span>{photosDetails[index][0]} </span>
                                      <span>{photosDetails[index][1]}</span>
                                      <span>{`, ${photosDetails[index][2]} `}</span> 
                                    </p>
                                  
                                  </div>
                   : ''}
                </div>
                }
                
            
          </div>
          
  
        ))}

     
      </Slider>

    </div>
  );
};

export default PhotosCarousel;