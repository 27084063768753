import React, { useEffect, useState } from "react";
import classes from "./HomePage.module.scss";
import SBTracking from "../../services/tracking";
import Hero from "../Hero/Hero";

import Data from "./homepageContent.json"; // TODO: Create this file
import ThinkInsideTheBox from "./ThinkInsideTheBox/ThinkInsideTheBox";
import GetInTouch from "./GetInTouch/GetInTouch";

import ReactFullpage from "@fullpage/react-fullpage";
import { ArtistShowcase } from "./ArtistShowcase/ArtistShowcase";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const HomePage = () => {
  const [fullPageAPIState, setFullPageAPIState] = useState(null);
  let [destinationVar, setDestinationVar] = useState();
  let [activeSrc, setActiveSrc] = useState(false);

  useEffect(() => {
    let pageViewData = {
      page_category: "Welcome",
      page_name: "App - Welcome"
    };
    SBTracking.pageView(pageViewData);
  }, []);

  // useEffect(() => {
  //   const moveToAboutButton = document.getElementById("moveToAbout");
  //   if (moveToAboutButton) {
  //     moveToAboutButton.addEventListener("click", () => {
  //       fullPageAPIState.moveTo(1);
  //     });
  //   }
  //   const moveToNewsButton = document.getElementById("moveToNews");
  //   if (moveToNewsButton) {
  //     moveToAboutButton.addEventListener("click", () => {
  //       fullPageAPIState.moveTo(2);
  //     });
  //   }

  //   return () => {
  //     if (moveToAboutButton) {
  //       moveToAboutButton.removeEventListener("click", () => {
  //         fullPageAPIState.moveTo(1);
  //       });
  //     }
  //     if (moveToNewsButton) {
  //       moveToNewsButton.removeEventListener("click", () => {
  //         fullPageAPIState.moveTo(2);
  //       });
  //     }
  //   };
  // }, [fullPageAPIState]);

  const loc = useLocation();

  useEffect(() => {
    if (!fullPageAPIState) {
      return;
    }
    if (!loc || !loc.state) {
      fullPageAPIState.moveTo(1);
      return;
    }

    if (loc.state.moveTo) {
      if (loc.state?.moveTo === "about") {
        fullPageAPIState.moveTo(2);
      } else if (loc.state?.moveTo === "news") {
        fullPageAPIState.moveTo(3);
      }
      loc.state.moveTo = undefined;
    }
  }, [loc, fullPageAPIState]);

  function afterLoad(origin, destination, direction) {
    setDestinationVar(destination.index);
  }

  useEffect(() => {
    if (destinationVar === 1) {
      setActiveSrc(true);
    } else {
      setActiveSrc(false);
    }
  }, [destinationVar, activeSrc]);

  return (
    <ReactFullpage
      scrollOverflow={true}
      licenseKey={"662A4A4D-A778442B-91B7CEFC-00B5C29A"}
      afterLoad={afterLoad}
      render={({ state, fullpageApi }) => {
        setFullPageAPIState(fullpageApi);
        return (
          <>
            <ReactFullpage.Wrapper>
              <div className="section">
                <div className={`${classes.HomePage} `}>
                  <Hero />
                </div>
              </div>
              <div className="section">
                <ThinkInsideTheBox content={Data.thinkInsideTheBox} sourceAdd={activeSrc} />
              </div>
              {Data.artistShowcase && (
                <div className="section">
                  <ArtistShowcase content={Data.artistShowcase} />
                </div>
              )}
              <div className="section">
                <GetInTouch content={Data.getInTouch} />
              </div>
            </ReactFullpage.Wrapper>
          </>
        );
      }}
    />
  );
};

export default HomePage;
