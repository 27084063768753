import axiosConfig from '../axiosConfig'

//get photographer by category
export function getPhotographerDetails(photographerId){
    return axiosConfig.get(`/photo-user/info/${photographerId}` )
}

//get all the categories from photo users
export function getPhotographerAllCategories(photographerId){
    return axiosConfig.get(`/photo-user/view-all/categories/${photographerId}` )
}