import React, {useRef, useState, useEffect} from 'react';
import { userService } from '../../services/userServices';
import { getFileName } from '../../helpers/FileCaching';
import {TweenMax, Power3} from 'gsap';
import {  useHistory } from 'react-router-dom';
import classes from './LoginPage.module.scss';
import SBTracking from '../../services/tracking';


const globalImages = require.context('../../images', true, /.*\.(gif|png|jpe?g|svg)$/i);

const LoginPage = () => {
    let history = useHistory()
    const authed = userService.isLoggedin();
    if( authed) history.push("/") ;
    
    const iPadMediaQ = window.matchMedia("(min-width: 1200px)");
    const logoRef = useRef();
    const formRef = useRef();
    const tempRef = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [submitted, setSubmitted] = useState(false);
    
    const [error, setError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
         
        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }
        localStorage.setItem('userName', username );


            userService.login(username, password)
            .then( res=> {        
                
            }).catch( err => {
                if ( err.response.status === 401 ){
                    SBTracking.trackEvent("Login", "Failed")
                    setError("These credentials do not match our records.")
                }
            })
                
    }

   


    useEffect(() => {
      
        if(iPadMediaQ.matches){
            TweenMax.set(logoRef.current, {scale:3.9, y:100});
            TweenMax.set([formRef.current, tempRef.current], {opacity:0});

            TweenMax.to(logoRef.current, .5, {delay: .5, scale:1, y:0,  ease: Power3.easeOut});
            TweenMax.to([formRef.current, tempRef.current], .5, {delay: .9, opacity:1,  ease: Power3.easeOut});
   
        }
   
      },[iPadMediaQ.matches]);

    return(
        <div className={classes.LoginPage}>
           <div className={`col-md-6 col-md-offset-3`}>
                <img ref={logoRef} className={classes.LoginPage__logo} src={ getFileName(globalImages, `logo.svg`)} alt="Squint Box Logo" />
                <form ref={formRef}  className={classes.LoginPage__form} name="form" onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <input type="text" className={`col-8 ${classes.LoginPage__form_input} ` + (submitted && !username ? `${classes.LoginPage__form_input__invalid}` : '') } name="username" placeholder="Email address" value={username} onChange={e => setUsername(e.target.value)} />
                        {submitted && !username &&
                            <div className="help-block text-danger">Email is required</div>
                        }
                    </div>
                    <div className='form-group'>
                        <input type="password" className={`col-8 ${classes.LoginPage__form_input} ` + (submitted && !password ? `${classes.LoginPage__form_input__invalid}` : '') } name="password" placeholder="Password"  value={password} onChange={e => setPassword(e.target.value)} />
                        {submitted && !password &&
                            <div className="help-block text-danger">Password is required</div>
                        }
                    </div>

               
                    <div className="form-group">
                        <button type="submit" className={classes.LoginPage__submit_button}>Login</button>
                    </div>

                    {error &&                        
                        <span className="text-danger">{error}</span>
                    }
                 
                </form>
            </div>
        </div>
    )
}

export default LoginPage;