import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.module.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';


// import axios from 'axios';

// axios.defaults.baseURL = 'https://squintbox-api.staging.node-production.com/squintbox-api';
// // axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
// axios.defaults.headers.common['Authorization'] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTM3ZTViZmZhYTQ4Yzc1NDBjOTYzZjVjOTkwMDcwMzM3MDhjOTI2YWM2MGQxYWM4ZmU4NTZiNjAwMDNiYzViYWYyOTI2NTY1MmZhNjcxNmEiLCJpYXQiOjE1ODM5MjI5NzgsIm5iZiI6MTU4MzkyMjk3OCwiZXhwIjoxNjE1NDU4OTc4LCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.I7IiWzRxg9HlWEkz2MIgx9-t4gQyGJ2DgYU-ezRaUqYhyH6jstiMewmBaeKxF4nD4v2l7V96Sw4SaHDYRhwokgVgV07yLWYcXOXBsbumCK6hbDExTDlP31pCb1AS6Q9QnC6Jp-Z0RNYUOLar2ZqUETKfExC2LI1A-tuNQl7NJUDCzxBUQtXMjbGFq4cYSsZdmo6lQTRZowr903eHxHJvIFZ0HhlAJ9QCKXi2c5dh3dQKc9yOzc9JrolkpDLJSJ0N5DGzYKibdb104IIYj-3Z2wJ64jpDRTk7kLJUBCwKqJNQo6PPtgVytkoAG2wCI68Moz55D-egZY-bNtRAy55ARDHRZ0hjm7G4q9a4uuJ87mLD77ioFtAT67pAUBsD3HDMxoBVHJSYxPufkww_peXRy3Ra9OY2CNsWggPRXNTzI6Ns-TDydUspUW2WR6ptVpZNpFZUTO8438oNvqVxOGI9pOEJkxOp1DmcvXACZ5xgn3ceu1TmILGI3woNohrbWMq7XXCqso-69NJR73wzUqIm0b1fEdqHIL0gI8jQl8YlB96WWtMJAwLkUiiQuJzoS3plD0dh7iW1TqE69_25SdO7YsUI4wDCPm_6KpLVR0w4P_Qejc-VyCjGkswClZvxav92s2W8R1LUnPp5h_IRilBnCz9o2Ikx-dViX24Yd_iAUB0`;
// axios.defaults.headers.post['Content-Type'] = 'application/json';




ReactDOM.render(
    <Router>
        <App />
  </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
