import { sha256, sha224 } from 'js-sha256';

class SBTracking {
    static SessionData = {
       
    }

    static gtmArtistTrackData =( 
        {
          'event':'artist_view',
          'ecommerce': {
            'detail': {
              'actionField': {'list': 'Category'},
              'products': [{
                'name': null,
                'id': null,
                'price': '00.01',
                'brand': '',
                'category': null,
                'variant': ''
              }]
            }
          }
        }
      )

    static trackArtistAssetView(assetData){

        let data = {
            'event':'artist_work_click',
            'page_category':(assetData.page_category ? assetData.page_category : 'Artist'), 
            'page_name':'App - ' + assetData.artist_name,
            'artist_name':assetData.artist_name,
            'artist_id':String((assetData.artist_id ? assetData.artist_id : 'N/A')),
            'artist_price':'N/A', //Photographer License Price
            'artist_brand':SBTracking.SessionData.artistBrand,
            'artist_category':assetData.artist_category,
            'artist_work_id':String(assetData.artist_work_id), //artist work id
            'artist_work_name':'N/A' //Not sure if available
          };

        SBTracking.gtmTrack(data)
    }

    static gtmTrack(data){

        let userName = window.localStorage.getItem('userName');
        let userID = null
        let organisation  = userName.split('@')[1];

        if(userName){
          userID = sha256(userName);
        }

        data.date_time = new Date().toLocaleString();
        data.user_id = userID;
        data.agency = organisation;
        window.dataLayer.push(data);

    }
    static trackEvent(action, label, value) {

        if(window.localStorage.getItem('userName')){

            window.gtag('event', action, {
                'event_category': window.localStorage.getItem('userName'),
                'event_label': label,
                'value':"value"
            });

        }else{

            console.error("User data is not availabe,  have you logged in?");

        }

    }

    static pageView(pageData){

      let data = {
        'event':'page_view',
        'page_category': (pageData.page_category ? pageData.page_category : 'Welcome'),
        'page_name': (pageData.page_name ? pageData.page_name : 'App - Welcome'), 
      };

      SBTracking.SessionData.pageCategory = pageData.page_category;
      SBTracking.SessionData.pageName = pageData.page_name;
      SBTracking.gtmTrack(data)
    }

    static artistClick(artistData){

      let data = {
        'event': 'artist_click',
        'ecommerce': {
          'click': {
            'actionField': {'list': 'Category'},
            'products': [{
              'name': artistData.artist_name,
              'id': String(artistData.artist_id),
              'price': '00.01',
              'brand': 'N/A //change to API required',
              'category': artistData.artist_category,
              'variant': '',
              'position': artistData.position
             }]
           }
         }
      };

      SBTracking.gtmTrack(data)
    }

}

export default SBTracking;

