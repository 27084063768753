
import { login, logout, isLoggedin, sendPasswordEmail, resetPassword} from './user.js'
import { createLightbox,
        getLighboxes,
        getLightboxebyID,         
        addToLightbox,
        deleteLightbox,         
        deleteLightboxMediaAsset,
        getLighboxeShareLink} from './lightbox.js'

import { getHeaderLogo, getCategory, getPhotoUser, getPhotoCollection } from './agency.js';
import { getPhotographerDetails, getPhotographerAllCategories } from './photoUser.js'

export const userService = {
    
    login, //user.js
    logout,
    isLoggedin,
    sendPasswordEmail,
    resetPassword,

    createLightbox, // lightbox.js
    getLighboxes,
    getLightboxebyID,
    addToLightbox,
    deleteLightbox,
    deleteLightboxMediaAsset,
    getLighboxeShareLink,

    getHeaderLogo, //agency.js
    getCategory,
    getPhotoUser,    
    getPhotoCollection,
    
    getPhotographerDetails, // photoUser.js
    getPhotographerAllCategories
    
    
};







