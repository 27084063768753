import React from 'react';
import { Route } from 'react-router-dom';
// import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, exact, path, authed, ...rest }) => {
    return(
        // <Route {...rest} render={ (props) => authed === true 
        //     ? <Component {...props} />
        //     : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        // } />
        <Route {...rest} path={path} render={(props) => <Component {...props} /> } />
    )
}

export default PrivateRoute;
