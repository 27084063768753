import React, { useEffect, useRef, useState } from "react";
import classes from "./ModalSharing.module.scss";
import { getFileName } from '../../../../../../helpers/FileCaching';
import SBTracking from '../../../../../../services/tracking'
const icons = require.context('../../../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);


const Modal = ({close, LightboxShareUUID}) => {

  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);
  
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    
    e.target.focus();
    setCopySuccess('The Link was Copied!');
  };

  useEffect(() => {

    SBTracking.gtmTrack({
      'event':'share',
      'page_category':'My Vault', 
      'page_name':'App - My Vault - '+SBTracking.SessionData.lightboxName,
      'date_time':'24/03/2021 - 09:23:11',
      'vault_name':SBTracking.SessionData.lightboxName,
      'vault_id':SBTracking.SessionData.lightboxId
   })

  },[])

  return (
    <div  className={classes.Modal}>
        <div className={`${classes.Modal__modalContent}`}>          

          <div className={classes.Modal__body}>
          <div className={classes.lightbox}>
            <div className={classes.lightbox__formGroup}>

              <input type="text"  ref={textAreaRef}  className={classes.lightbox__formGroupInput} defaultValue={`${window.location.origin}/lightbox/${LightboxShareUUID}`}/>
              {/* <button onClick={copyToClipboard} className={classes.lightbox__formGroupClose}>Copy</button>   */}
              <button onClick={copyToClipboard} className={classes.lightbox__copy}>
                <div  className={`${classes.lightbox__icon} ${classes.lightbox__copyIcon}`} 
                  style={
                      {
                        backgroundImage: 'url(' + getFileName(icons, 'copy_lightbox.svg') + ')'
                      }
                    }>
                </div>
              </button>              
         
            </div>
            <p className={classes.lightbox__textSuccess} >
              {copySuccess}
            </p>
          
            <button onClick={close} className={classes.lightbox__close}>
              <div  className={classes.lightbox__icon } 
                style={
                    {
                      backgroundImage: 'url(' + getFileName(icons, 'close_lightbox.svg') + ')'
                    }
                  }>
              </div>
            </button>
          </div>

          </div>
        </div>
      </div>
     
  );
};

export default Modal;
