import classes from "./ThinkInsideTheBox.module.scss";

import React, { useRef, useState, useEffect } from "react";
import Data from "../homepageContent.json";

const ThinkInsideTheBox = ({ content, sourceAdd }) => {
  const [isWindow, setIsWindow] = useState(false);

  const video = useRef();

  useEffect(() => {
    if (sourceAdd === true) {
      video.current.play();
    } else {
      video.current.pause();
    }
  }, [sourceAdd]);

  useEffect(() => {
    if (navigator.appVersion.indexOf("Win") !== -1) {
      setIsWindow(true);
    } else {
      setIsWindow(false);
    }
  }, [isWindow]);

  return (
    <>
      <div className={`${classes.thinkInsideTheBox}`}>
        <div className={`row ${classes.thinkInsideTheBox__content}`}>
          <div className={`col-md-12 col-lg-6 col-xl-6`}>
            <video
              className={`${classes.thinkInsideTheBox__video}`}
              ref={video}
              width="100%"
              height="100%"
              autoPlay
              webkit-playsinline="true"
              muted="muted"
              playsInline={true}>
              <source src={`${Data.videoSection.src}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6">
            <h2 className={`${classes.thinkInsideTheBox__aboutTitle}`}>{content.title}</h2>
            <p
              className={`${classes.thinkInsideTheBox__description} ${
                isWindow ? classes.thinkInsideTheBox__descriptionWin : ""
              }`}
              dangerouslySetInnerHTML={{ __html: content.description }}></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThinkInsideTheBox;
