import React, { useRef, useState, useEffect, useCallback} from "react";
import classes from "./User.module.scss";
import Modal from "../User/Modal/Modal";
import CreateUpdateModal from "./Lightbox/CreateUpdateModal/CreateUpdateModal";
import PhotoCollectionItem from "./PhotoCollectionItem/PhotoCollectionItem"

import {TweenMax, Power3} from 'gsap';
import { userService } from '../../../services/userServices'
import { useParams, useHistory, useLocation } from "react-router-dom";

import content from "../../../data/content.json";
import Spinner from "../../Spinner/Spinner";

import { addToLightbox } from "../../../services/lightbox";

import SBTracking from "../../../services/tracking"


const User = () => {


  const [sendPhotos, setSendPhotos] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [lightboxmodalIsOpen, setLightboxModalIsOpen] = useState(false);

  const [infoImg, setInfoImg] = useState([]);
  const [infoData, setInfoData] = useState([]);

  const [loading, setLoading] = useState(true);

  const nameRef= useRef();
  const bioRef= useRef();
  const websiteRef= useRef();
  const contactRef= useRef();
  const agencyRef= useRef();
  const imgsRef = useRef([]);
  const viewAllBtn = useRef();

  const lightboxBtn = useRef();

  const [isWindow, setIsWindow] = useState(false);

  //Storage for all users photos in
  const [allCatImagesMarkup, setAllCatImagesMarkup] = useState([]);
  const [allCatImageUrls, setAllCatImageUrls] = useState([]);
  const [allPhotoUrlsForCaoursel,setAllPhotoUrlsForCaoursel]  = useState([]);

  const [singleCatImagesMarkup, setSingleCatImagesMarkup] = useState([]);
  const [singleCatImageUrls, setSingleCatImageUrls] = useState([]);


  const [isAddlightboxBtn, setisAddlightboxBtn] = useState(false)

  const [selectedMediaAssetId, setselectedMediaAssetId] = useState([]);

  const [allCategoriesId, setAllCategoriesId] = useState([]);
  const arr = [];
  const [allImages, setAllImages] = useState([]);



  const [hideAllCategories, setHideAllCategories] = useState(true);
  const [showViewAll, setShowViewAll] = useState(false);

 

  const [allIn, setAllIn] = useState([]);

  let history = useHistory();
  
  let uselocation = useLocation();

  // get params from url 
  let { photographer_id, category_id } = useParams();

  const [catTitle, setCatTitle] =  useState();
  
  const resetselectedMediaAssetId = useCallback( (e) => {

    //clear images
    setselectedMediaAssetId([])

    // setActiveId(null)

    //get all inputs checkbox
    var checkboxInputs = document.querySelectorAll("input[type=checkbox]")

    // uncheck all the checkboxes
    for (let checkbox of checkboxInputs) {
      checkbox.checked = false;
    }
  },[])
  
  
  const handleOnChange = e => {
    // variable 
    let mediaId = (e.target.dataset.mediaassetid)

    //check if checkbox is true
    if ( e.target.checked ) {
      //add frame to selected item
      imgsRef.current.map(val => {

          if(val.dataset.mediaassetid === mediaId){
            val.classList.add('imgSelected')
          }
        
      })
      //show sticky addlightbox button
      setisAddlightboxBtn(true);
 
     
      //add new id to array
      setselectedMediaAssetId( selectedMediaAssetId =>  [...selectedMediaAssetId,  mediaId ])

    }else{
    
      //remove id  if already exist
      setselectedMediaAssetId( selectedMediaAssetId  => selectedMediaAssetId.filter(e => e !== mediaId) )
 
      //remove frame when not selected item
      imgsRef.current.map(val => {

        if(val.dataset.mediaassetid === mediaId){
          val.classList.remove('imgSelected')
        }
      
    })

    }   
    
  }

  ////fixing style on win viewAllWork button
  useEffect(() => {
    if (navigator.appVersion.indexOf("Win") !== -1){
      setIsWindow(true) 
    }else{
      setIsWindow(false) 
    }
  },[ isWindow]);

  
    //constructor
  useEffect(() => {
    
    //photograper API details
    userService.getPhotographerDetails( photographer_id )
    .then( (res) => {            
        setLoading(false)
        let data = res.data;
        const Descr = Object.values(data).map(value => value);
        
        SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].name = Descr[0].first_name+" "+Descr[0].last_name 
        SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].id = String(Descr[0].id)

        if(data[0].website != ''){
          SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].brand = data[0].website
          SBTracking.SessionData.artistBrand = SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].brand
        }
        else{
          SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].brand = ''
          SBTracking.SessionData.artistBrand = ''
        }
        
        let pageViewData = {
          page_category:'Artist',
          page_name:'App - ' + SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].name
        }
        SBTracking.pageView(pageViewData);

        trackArtistPageLoad();
        setInfoData(
                
          Descr.map((val, index) =>  (
            <div key={index}>
              <h3 ref={nameRef} className={classes.User__name}>{val.first_name} {val.last_name}</h3>
              <p ref={bioRef} className={classes.User__description}>{val.bio}</p>
              {(val.logo_path != null)
              ?
                <div className={classes.User__logo_container}>
                  {(val.website != null)
                  ?
                    <a href={val.website} target="_blank" rel="noopener noreferrer" onClick={() => gtagClick(val)}>
                      <img alt={`${uselocation.state.userType}s logo`} width="200" src={val.logo_path}></img>
                    </a>
                  :
                   <img alt={`${uselocation.state.userType}s logo`} width="200" src={val.logo_path}></img>
                  }
                </div>
              :
                <div>
                  {(val.website != null)
                  ?
                    <h6 ref={websiteRef} className={classes.User__website}>
                      <a href={val.website} target="_blank" rel="noopener noreferrer" onClick={() => gtagClick(val)}>{val.website}</a>
                    </h6>
                  :
                    ""
                  }
                </div>
              }
             
              {(val.email != null ) 
              ? 
              <div ref={contactRef} className={classes.User__infoContainer}>
                {
                      ( uselocation.state.userType === 'photographer')
                      ?
                      <p className={classes.User__infoTitle}>{content.defaultData.contactPhotographer}</p>
                      :
                      <p className={classes.User__infoTitle}>{content.defaultData.contactDirector}</p>
                }

                <h6><a href={`mailto:${val.email}?Subject=Hello%20`}>{val.email}</a></h6>
              </div> 
              : 
              ""
              }
              {(val.agent_contact_email != null)
              ?
              <div ref={agencyRef} className={classes.User__infoContainer}>
                <p className={classes.User__infoTitle}>{content.defaultData.contactAgent}</p>
                 <h6 className={classes.User__agent}><a href={`mailto:${val.agent_contact_email}`}>{val.agent_contact_email}</a></h6>
              </div>
              :
              ""  
              }
            </div>
          ))

      );

    
      // TweenMax.set(viewAllBtn.current, {opacity:0, y: -20 })
      TweenMax.staggerFromTo([nameRef.current, bioRef.current, websiteRef.current, contactRef.current, agencyRef.current], .5, {opacity:0, y:- 20}, {
        opacity: 1, y:0, ease: Power3.easeOut
      }, 0.25);

  // if(viewAllBtn.current){
       

      // }

    })

    .catch( err => {
        userService.logout()
        history.push("/login")
      }
    );

  
    //get all the categories where the photo user is included
    userService.getPhotographerAllCategories( photographer_id )
    .then( (res) => {  
      let allCategories = res.data;  
      
      let allArr =[];
      // allCategories.map(val => allArr.push(val.id));
      allCategories.map(val => {
        if(val.parent_flag === 0){
          allArr.push(val.id)
        }}
      );

      /////////subcategory ids
      allCategories.map(val =>{
        val.child_category.map(v =>{
          allArr.push(v.id)
        })
      })

      setAllCategoriesId(allArr);

    })


    //images API 
    userService.getPhotoCollection(category_id, photographer_id )
    .then( (res) => {    
      let allPhotos = res.data.photos;
      let allPhotoUrlsForCaoursel = []
      let photoCollectionMarkup = []
      
      let categoryMarkup = 
      <>
        <div className={`siblingIMGContainer ${classes.User__photoContainerGrid}`}>
          {Object.values(allPhotos).map((val, index) =>{
            allPhotoUrlsForCaoursel.push(val)
            
            return <PhotoCollectionItem key={index} showCarouselCallback={showCarousel} lightboxCheckCallback={handleOnChange} content={val} imagesRef={imgsRef} collectionIndex={index}/>
            
          })}
        </div>
      </>
      
      

      photoCollectionMarkup.push(categoryMarkup)
      
      //All category data has been mapped and downloaded
      setSingleCatImageUrls(allPhotoUrlsForCaoursel)
      setSingleCatImagesMarkup(photoCollectionMarkup)
      
      //Display the category
      setAllPhotoUrlsForCaoursel(allPhotoUrlsForCaoursel)
      setInfoImg(photoCollectionMarkup)

      setCatTitle(res.data.category.info[0].name)
      SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].category = res.data.category.info[0].name;
      trackArtistPageLoad();
    })


  },[]);

  function trackArtistPageLoad(){
    if(
      SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].name != null 
      &&
      SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].id != null
      &&
      SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].category != null
      
    ){
      SBTracking.gtmTrack(SBTracking.gtmArtistTrackData);
    }

  }

  function onPhotoChanged(photo){
    
    let photoUrlParts = photo.url_path.split('/');
    let photoFileId = photoUrlParts[photoUrlParts.length-1]

    let data = {
      artist_name:SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].name,
      artist_category:(hideAllCategories ? SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].category : "View All Categories"),
      artist_work_id:photo.id,
      artist_id:SBTracking.gtmArtistTrackData.ecommerce.detail.products[0].id
    }
  
    SBTracking.trackArtistAssetView(data);
    
  }

  useEffect(() =>{
    //remove addlightbox button if not image is selected
    if (selectedMediaAssetId === undefined ||selectedMediaAssetId.length == 0) {
      setisAddlightboxBtn(false)
    }

  }, [selectedMediaAssetId, addToLightbox, lightboxBtn, isAddlightboxBtn])

  useEffect(() =>{
    TweenMax.set(viewAllBtn.current, {opacity:0, y: -20 })
    TweenMax.to(viewAllBtn.current, 3, {delay:1, opacity:1, y:0, ease: Power3.easeOut})


  }, [])

  /////////get all the photos from all categories
  useEffect(() =>{
    let categoryIndex = 0
    let photoCollectionMarkup = []
    let allPhotoUrlsForCaoursel = []
    let previousCategoryPhotoTotalCount = 0

    allCategoriesId.map((allcat) =>{
      userService.getPhotoCollection(allcat, photographer_id )
      .then( (res) => {

          let allPhotos = res.data.photos;
          let categoryName = res.data.category.info[0].name;

          
          let categoryMarkup = 
            <>
              <div className={classes.User__categoryName}>
                <h5>{categoryName}</h5>
              </div>
              <div className={`siblingIMGContainer ${classes.User__photoContainerGrid}`}>
                {Object.values(allPhotos).map((val, index) =>{
                  allPhotoUrlsForCaoursel.push(val)
                  return <PhotoCollectionItem key={index} showCarouselCallback={showCarousel} lightboxCheckCallback={handleOnChange} content={val} imagesRef={imgsRef} collectionIndex={previousCategoryPhotoTotalCount+index}/>
                })}
              </div>
            </>

          photoCollectionMarkup.push(categoryMarkup)
          
          //Store the total of all previous photos to assign the correct index for the carousel
          previousCategoryPhotoTotalCount += allPhotos.length
          categoryIndex++
          
          if(categoryIndex == allCategoriesId.length){
            //All category data has been mapped and downloaded
            setAllCatImageUrls(allPhotoUrlsForCaoursel)
            setAllCatImagesMarkup(photoCollectionMarkup)
          }

      })

    })
  }, [allCategoriesId])

  
  function checkLenghtCategory(){
   
    if(allCategoriesId.length > 1 && uselocation.state.userType === 'photographer'){
      setShowViewAll(true);
    }else{ setShowViewAll(false)
    }
  
  }

  useEffect(() =>{
    checkLenghtCategory();
  }, [allCategoriesId])


  useEffect(() =>{

    if(!hideAllCategories){

      setAllPhotoUrlsForCaoursel(allCatImageUrls)
      setInfoImg(allCatImagesMarkup)

    }else{

      setAllPhotoUrlsForCaoursel(singleCatImageUrls)
      setInfoImg(singleCatImagesMarkup)

    }

  }, [hideAllCategories])


////toggle button show all works
  const showAllCategories = () => {
    if (!hideAllCategories) {
      setHideAllCategories(true)
    
    }else{setHideAllCategories(false)
 
    }
 }





  function gtagClick(val){
   
		window.gtag('event', "photographer website clicked", {
		    'event_category': content.tracking.ga_category,
			  'event_label': `${val.website} photographer website clicked`,
        'value':10
        
          });
    }

    function showLightboxModal(){
      setLightboxModalIsOpen(true)
    }

    function hideLightboxModal(){
      setLightboxModalIsOpen(false)
    }
    
    
  function showModal(){
    setModalIsOpen(true)
  }
  function hideModal(){
    setModalIsOpen(false)
  }

  function showLightbox(){
    
    setLightboxModalIsOpen(true)
  }

  function showCarousel(val, index){
    setSendPhotos(index)
    setModalIsOpen(true)
  }

  return (
    <div className={classes.User}>
        <div className={`col-md-12 col-lg-8 ${classes.User__header}`}>
            <h5 className={classes.User__title}>
              {(
                hideAllCategories
                ?
                catTitle
                :
                "ALL WORK"
              )}

            </h5>
        </div>
 
             
        <div className={`col-md-12 ${classes.User__wrapper}`}>
       
       
        {
        loading 
          ?
            <Spinner/>
          :
            <div className={`row ${classes.User__row}`}>
              <div className={`col-lg-5 ${classes.User__infoData}`}>            
                {infoData}

                {showViewAll === true ?
                  <button ref={viewAllBtn} type="text" className={`secondaryBtn ${classes.User__btnViewAll} ${ isWindow ? classes.User__btnViewAll__win : "" }`} onClick={showAllCategories}>
                    {hideAllCategories === true ? 'VIEW ALL WORK' : 'BACK TO CATEGORY PHOTOS'}
                  </button>
                  : ''
                }

          
                </div>
              <div className={`col-lg-7`}> 

                  
                <div className={`${classes.User__photoContainer}`}>
              
                  { isAddlightboxBtn && <button className={classes.User__addLightbox} ref={lightboxBtn} onClick={ () => showLightbox()}>add to vault</button> }
                  {infoImg}

                </div>

              </div>

            </div>
          }
        </div>
      
        {/* <ScrollButton /> */}

       {modalIsOpen && 
        <Modal open={showModal} close={hideModal} photos={allPhotoUrlsForCaoursel} photoItem={sendPhotos} onChangeCallback={onPhotoChanged} />
        } 


        {lightboxmodalIsOpen && 
        <CreateUpdateModal open={showLightboxModal} close={hideLightboxModal} selectedMediaAssetId={selectedMediaAssetId} resetselectedMediaAssetId={resetselectedMediaAssetId}/>
        } 
       
      

    </div>
  );
};

export default User;
