import React, {useEffect} from "react";
import { getFileName } from '../../../../helpers/FileCaching';
import classes from "./Modal.module.scss";
import PhotosCarousel from "../Carousel/Carousel";

const icons = require.context( '../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);

const Modal = ({open, close, photos, photoItem, content, clickedId, photosDetails, onChangeCallback}) => {
  return (
    <div  className={classes.Modal}>
        <div className={`${classes.Modal__modalContent}`}>
          <div className={classes.Modal__header}>
            <button className={classes.Modal__btnclose} onClick={close}>
              <img height='50px' width='50px' className={classes.Modal__close} src={ getFileName(icons, `close.svg`)} alt="close" />
            </button>
          </div>
          <div className={classes.Modal__body}>
              <PhotosCarousel photos={photos} photoItem={photoItem} photosDetails={photosDetails} clickedId={clickedId} content={content} onPhotoChangeCallback={onChangeCallback}/>
          </div>
        </div>
      </div>
     
  );
};

export default Modal;
