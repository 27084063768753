import React, { useState, useEffect, useRef} from "react";
import { useParams, useHistory } from 'react-router-dom';
import { userService } from '../../../../../services/userServices'
import classes from "./LightboxSharelink.module.scss"

import Spinner from "../../../../Spinner/Spinner"
import Modal from "../../Modal/Modal";
import SBTracking from '../../../../../services/tracking'

import { getFileName } from '../../../../../helpers/FileCaching';
const icons = require.context('../../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);


const LightboxSharelink = () => {

  const [isClicked, setIsClicked] = useState(false);
  const [sendMediaAssets, setSendMediaAssets] = useState();

  const [Lightbox, setLightbox] = useState([]);  
  const [Lightboxname, setLightboxname] = useState();
  const [LightboxdestailCount, setLightboxdestailCount] = useState([]);  
  const [loading, setLoading] = useState(true);
  const [showPlayIcon, setshowPlayIcon] = useState(true);

  const videoPlayIcon = getFileName(icons, 'play-button.svg');

  const [imgDetails, setImgDetails] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false);
  

  let { sharelink } = useParams();
  let history = useHistory()
  
useEffect(() => {

    userService.getLighboxeShareLink(sharelink)
    .then( res => {   
      setLoading(false)      
      setLightboxdestailCount( (Object.keys(res.data.lightbox.media_asset).length ) === 1 ? ` / ${Object.keys(res.data.lightbox.media_asset).length} image` : ` / ${Object.keys(res.data.lightbox.media_asset).length} images`  );
      setLightboxname(res.data.lightbox.name);
      setLightbox(res.data.lightbox.media_asset);
      setImgDetails(Object.values(res.data.lightbox.media_asset).map(val => ([val[0].first_name, val[0].last_name, val[0].category_name])))
      

    }).catch( err => {            
        // history.push("/")
    });
  },[])  

const MediaAssets = Object.values(Lightbox).map(media_asset => {

  media_asset[0].url_path = media_asset[0].path
  return media_asset[0]
});

  function VideoPlayIcon(props) {
  
    const playIcon = getFileName(icons, 'play-button.svg');
    let img;
    
    if (showPlayIcon) {
        img = <img className={props.className} src={playIcon} alt="" />
    } else {
      img = null
    }
    return img;
  }

  function showCarousel(val, index){
    if(!isClicked)setIsClicked(true);
    else setIsClicked(false); 

    setModalIsOpen(true)
    setSendMediaAssets(index)
  }

  function showModal(){
    setModalIsOpen(true)
  }
  function hideModal(){
    setModalIsOpen(false)
  }

  function onPhotoChange(info, detail){

    let filePathParts = info.url_path.split("/")
    let filename = filePathParts[filePathParts.length-1]

    SBTracking.trackArtistAssetView({
      artist_name: detail[0]+" "+detail[1],
      artist_category:detail[2],
      artist_work_id:info.id,
      page_category:"Vault"
    })

  }

  function printPage(){


    window.print();


    
    
  }
  
    return(
      <>
      {loading && <Spinner/>}
        <div className={`row ${classes.lightboxdetail__container}`} id='ifmcontentstoprint'>

          <div className={`${classes.lightboxdetail__header} col-md-6`}>
            <h5>{Lightboxname} {LightboxdestailCount} </h5>
          </div>
            <div className={`col-md-6 ${classes.lightboxdetail__printContainer}` }>
              <button className = {`${classes.lightboxdetail__print}`}
                onClick = {printPage}
              >
                  <h6>print</h6>
              </button>
            </div>

          </div> 
        <div className={`siblingIMGContainer ${classes.lightboxdetailcontainer}`}>
                    
          {
              Lightbox.map((val,index) =>  (
                <div key={index}  className={`siblingImgH ${classes.mediaAssetItem}`}>

                    { val[0].ext ===  "mp4"
                    ?
                    <>
                      <img className={classes.lightboxdetail__videoPlayIcon} src={videoPlayIcon} alt="" onClick={ () => showCarousel(val, index)} />
                      <video height='80%' width='100%' playsInline onClick={ () => showCarousel(val, index)}>
                        <source src={`${val[0].path}#t=9`} type="video/mp4" />
                      </video>
                      <div className={classes.lightboxdetail__imgTitle}>
                        <p>
                          <span>{val[0].first_name} </span>
                          <span>{val[0].last_name}</span>
                          <span>{`, ${val[0].category_name} `}</span> 
                        </p>
                      </div> 
                    </>
                    :
                    <>
                      <img src={val[0].l_path} className={classes.lightboxdetail__img} alt="" onClick={ () => showCarousel(val, index)} /> 
                      <div className={classes.lightboxdetail__imgTitle}>
                        <p>
                          <span>{val[0].first_name} </span>
                          <span>{val[0].last_name}</span>
                          <span>{`, ${val[0].category_name} `}</span> 
                        </p>
                      </div>   
                    </>   
                    }
                </div>
              ))
          }
          
    </div>

        {modalIsOpen && 
        <Modal open={showModal} close={hideModal} photos={MediaAssets} photoItem={sendMediaAssets} photosDetails={imgDetails} onChangeCallback={onPhotoChange} />
        } 
      </>
    )
}

export default LightboxSharelink;