import React, { useState, useEffect, useRef } from "react";
import classes from "./Header.module.scss";
import { getFileName } from "../../helpers/FileCaching";
import content from "../../data/content.json";
import { useHistory, useLocation, Link } from "react-router-dom";
import Logout from "../Logout/Logout";
import { userService } from "../../services/userServices";
import SBTracking from "../../services/tracking";

const headerImages = require.context("../../images/Header", true, /.*\.(gif|png|jpe?g|svg)$/i);

const Header = () => {
  let history = useHistory();
  let url = useLocation();

  const [directorsAccess, setDirectorsAccess] = useState(localStorage.getItem("directors_access"));
  const [photographersAccess, setPhotographersAccess] = useState(localStorage.getItem("photographers_access"));

  const [data, setData] = useState([]);
  useEffect(() => {
    if (!url.pathname.includes("lightbox/")) {
      userService
        .getHeaderLogo()
        .then(res => {
          setData(res.data.Agency);
          SBTracking.SessionData.Agency = res.data.Agency[0];
        })
        .catch
        // error => setError(error),
        ();
    }
  }, []);

  const getNavHeight = () => {
    const nav = document.getElementById("header");
    return nav?.clientHeight;
  };

  useEffect(() => {
    setHeaderHeight(getNavHeight());
  }, []);

  const [headerHeight, setHeaderHeight] = useState(0);

  window.addEventListener("resize", () => {
    const navHeight = getNavHeight();

    setHeaderHeight(navHeight);
  });

  return (
    <header style={{ height: headerHeight }} className={classes.Header}>
      <div id="header" className={` ${classes.Header__fixingContainer}`}>
        <div
          style={{
            flexWrap: "inherit",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: 0,
            marginRight: 0
          }}
          className={`row`}>
          <div className={`${classes.Header__left}`}>
            <button
              className={classes.Header__squintLogo}
              onClick={() => history.push("/")}
              style={{
                backgroundImage: "url(" + getFileName(headerImages, "squint_logo.svg") + ")"
              }}></button>
            {/* <button
              className={classes.Header__clientLogo}
              onClick={() => history.push("/")}
              style={{ backgroundImage: agencyLogo ? `url(${agencyLogo})` : "" }}></button> */}
          </div>

          {!url.pathname.includes("lightbox/") ? (
            <div className={`${classes.Header__right}`}>
              <Logout />
              <nav className={classes.Header__nav}>
                <ul className={`d-flex  ${classes.Header__right__menu} `}>
                  <>
                    {photographersAccess === "1" ? (
                      <li
                        className={`${classes.Header__userType} ${
                          (url.state ? url.state.userType : null) === "photographer"
                            ? classes["Header__userType--active"]
                            : null
                        }`}>
                        <Link
                          to={{
                            pathname: "/categories",
                            state: { userType: "photographer" }
                          }}>
                          <h6>Photographers</h6>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {directorsAccess === "1" ? (
                      <li
                        className={`${classes.Header__userType} ${
                          (url.state ? url.state.userType : null) === "director"
                            ? classes["Header__userType--active"]
                            : null
                        }`}>
                        <Link
                          to={{
                            pathname: "/categories",
                            state: { userType: "director" }
                          }}>
                          <h6>Directors</h6>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className={`${classes.Header__userType}`}>
                      <Link to={{ pathname: `/categories/92/404`, state: { userType: "photographer" } }}>
                        <h6>Production services</h6>
                      </Link>
                    </li>
                    <li id="moveToNews" className={`${classes.Header__userType}`}>
                      <Link
                        to={{
                          pathname: "/",
                          state: { moveTo: "news" }
                        }}>
                        <h6>News</h6>
                      </Link>
                    </li>
                    <li id="moveToAbout" className={`${classes.Header__userType}`}>
                      <Link
                        to={{
                          pathname: "/",
                          state: { moveTo: "about" }
                        }}>
                        <h6>About</h6>
                      </Link>
                    </li>
                  </>
                  <li>
                    <a className={classes.Header__rightEmail} href="mailto:info@squint-box.com?Subject=Hello%20">
                      <h6>{content.header.messageRight}</h6>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
