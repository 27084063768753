import React, { useState, useEffect, useRef} from "react";
import { Link, useLocation } from 'react-router-dom';
import { userService } from '../../../../services/userServices'
import SBTracking from '../../../../services/tracking'

import Spinner from "../../../Spinner/Spinner"
import ConfirmModal from '../Lightbox/ModalConfirmation/ModalConfirmation'
import classes from "./Lightbox.module.scss"
import { TweenMax, Power3 } from "gsap/gsap-core";
import { getFileName } from '../../../../helpers/FileCaching';
const icons = require.context('../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);


const Lightbox = () => {

  let pageViewData = {
    page_category:'My Vault',
    page_name:'App - My Vault'
  }
  SBTracking.pageView(pageViewData);
 
  const [Lightboxes, setLightboxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const listRef = useRef([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [lightboxDelete, setLightboxDelete] = useState();

  let uselocation = useLocation();

  const confirmDeleteLightbox = (lightboxes_parent_id) => {

     if (!confirmDelete) setConfirmDelete(true);
     else setConfirmDelete(false);

     setLightboxDelete(lightboxes_parent_id)
    
  }

  const deleteLightbox = (lightboxDelete) => {
     userService.deleteLightbox(lightboxDelete)

     const newLightbox = Lightboxes.filter((item) => item.id !== lightboxDelete);
     setLightboxes(newLightbox)
   
  }

  function showConfirmDeleteModalIsOpen() {
    setConfirmDelete(true);
  }

  function hideConfirmDeleteModalIsOpen() {
    setConfirmDelete(false);
  }
  
  useEffect(() => {
    userService.getLighboxes()
    .then(res => {
      setLoading(false)
      setLightboxes(res.data)   
      TweenMax.set(listRef.current, {opacity:0, y:- 20});
      TweenMax.staggerTo(listRef.current, .25, {
          opacity: 1, y:0, ease: Power3.easeOut
      }, .15);

    })
  },[]);

  
  function DeleteIcon(props) {
    const playIcon = getFileName(icons, 'lightbox_delete_icon.svg');
    const playIconHover = getFileName(icons, 'lightbox_delete_icon_hover.svg');
    return (

      <img src={playIcon} alt=''/>
    
    );
  }

    return(
      <>
         {loading && <Spinner/>}
         <div className={classes.lightboxes}>
          <ul className = {`siblingContainer ${classes.lightboxes__list}`}>
            { 

              Lightboxes.map((val, ind) =>  (
                <li key={val.id} ref={el => listRef.current[ind] = el} className={`siblingCatH ${classes.lightboxes__listItem}`}>
                    <button className={classes.lightboxes__listBtn} onClick={ () => confirmDeleteLightbox(val.id) }>
                      <DeleteIcon className={classes.lightboxes__delete}/>
                    </button>
                        <Link to={{ 
                            pathname: `/lightboxes/${val.id}`,
                            state:  {userType: uselocation.state.userType }
                        }}
                        className={classes.lightboxes__link}>
                        <h3 className={classes.lightboxes__title}>{val.name}</h3>
                      </Link>
                </li>
              ))

            }
          </ul>
        </div>

        {confirmDelete &&
          <ConfirmModal open={showConfirmDeleteModalIsOpen} close={hideConfirmDeleteModalIsOpen} lightboxesPARId={lightboxDelete} deleteLightbox={deleteLightbox} />
        }
      </>
    )
}

export default Lightbox;