import React, { useState, useEffect, useRef} from "react";
import { useParams  } from 'react-router-dom';
import { userService } from '../../../../../services/userServices';

import classes from "./LightboxDetail.module.scss"
import CreateUpdateModal from "../CreateUpdateModal/CreateUpdateModal"
import ShareModal from "./ModalSharing/ModalSharing"
import ConfirmModal from "../ModalConfirmation/ModalConfirmation"

import Spinner from "../../../../Spinner/Spinner"
import Modal from "../../Modal/Modal";

//Tracking
import SBTracking from '../../../../../services/tracking'

import { getFileName } from '../../../../../helpers/FileCaching';
const icons = require.context('../../../../../images/icons', true, /.*\.(gif|png|jpe?g|svg)$/i);



const LightboxDetail = () => {

  // const [Lightboxdestail, setLightboxdestail] = useState();  
  const [isClicked, setIsClicked] = useState(false);
  const [sendMediaAssets, setSendMediaAssets] = useState();

  const [LightboxAPIContent, setLightboxAPIContent] = useState([]);  
  const [Lightboxname, setLightboxname] = useState();  
  const [LightboxdestailCount, setLightboxdestailCount] = useState([]);  
  const [lightboxmodalIsOpen, setLightboxModalIsOpen] = useState(false);
  
  const [sharemodalIsOpen, setShareModalIsOpen] = useState(false);
  const [LightboxShareUUID, setLightboxShareUUID] = useState(false);

  const [loading, setLoading] = useState(true);
  const [LightboxStatus, setLightboxStatus] = useState(false);
  const [showPlayIcon, setshowPlayIcon] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const videoPlayIcon = getFileName(icons, 'play-button.svg');
  const playIcon = getFileName(icons, 'lightbox_delete_icon.svg');
  const playIconHover = getFileName(icons, 'lightbox_delete_icon_hover.svg');

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [imgDelete, setImgDelete] = useState();
  const [imgDetails, setImgDetails] = useState([])

  let { lightboxes_id } = useParams();

  const handleMouseEnter = (e) => {
    e.target.src = playIconHover;
  };
  
  const handleMouseLeave = (e) => {
    e.target.src = playIcon;    
  }

  const deleteAddMediaAsset = (mediaAssetId) => {

    if (!confirmDelete) setConfirmDelete(true);
    else setConfirmDelete(false);

    setImgDelete(mediaAssetId)

  }

  const dellIMg = (imgDelete, lightboxes_id) => {
     userService.deleteLightboxMediaAsset(imgDelete, lightboxes_id)

     const newLightboxAPIContent = LightboxAPIContent.filter((item) => item[0].id !== imgDelete);
     setLightboxAPIContent(newLightboxAPIContent)

     if (newLightboxAPIContent == 0){
       setLightboxdestailCount(`${0} images`)
     }
   
  }
  

useEffect(() => {
    
    userService.getLightboxebyID(lightboxes_id)
  
    .then( res => {
     
      setLoading(false)
      
      if ( res.data.media_asset )
      {
     
        setLightboxdestailCount( (Object.keys(res.data.media_asset).length ) === 1 ? ` / ${Object.keys(res.data.media_asset).length} image` : ` / ${Object.keys(res.data.media_asset).length} images`  );
        setLightboxname(res.data.lightbox[0].name)
        setLightboxAPIContent(res.data.media_asset)
        setLightboxShareUUID(res.data.lightbox[0].uuid)
        setImgDetails(Object.values(res.data.media_asset).map(val => ([val[0].first_name, val[0].last_name, val[0].category_name])))
        
      }else {
        setLightboxStatus(true)
        
      }

      let pageViewData = {
        page_category:'My Vault',
        page_name:'App - ' + res.data.lightbox[0].name
      }
      
      SBTracking.SessionData.lightboxName= res.data.lightbox[0].name;
      SBTracking.SessionData.lightboxId= res.data.lightbox[0].id;
      SBTracking.pageView(pageViewData);
    }).catch (err => {
      // ///
      // console.log(err)
    });
},[])

const MediaAssets = Object.values(LightboxAPIContent).map(media_asset => {

  media_asset[0].url_path = media_asset[0].path
  return media_asset[0]
});

  function DeleteIcon() {

    return (      
        <img onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} src={playIcon} alt=""/>
    );
  }


  function showLightboxModal(){
    setLightboxModalIsOpen(true)
  }
  function hideLightboxModal(){
    setLightboxModalIsOpen(false)
  }
  function showShareModal(){
    setShareModalIsOpen(true)
  }
  function hideShareModal(){
    setShareModalIsOpen(false)
  }
  function showConfirmDeleteModalIsOpen() {
    setConfirmDelete(true);
  }
  function hideConfirmDeleteModalIsOpen() {
    setConfirmDelete(false);
  }
  function showCarousel(val, index){
     if (!isClicked) setIsClicked(true);
     else setIsClicked(false);

     setModalIsOpen(true)
     setSendMediaAssets(index)
    
  }

  function showModal(){
    setModalIsOpen(true)
  }
  function hideModal(){
    setModalIsOpen(false)
  }

  function onPhotoChange(info, detail){

    let filePathParts = info.url_path.split("/")
    let filename = filePathParts[filePathParts.length-1]
    SBTracking.trackArtistAssetView({
      artist_name: detail[0]+" "+detail[1],
      artist_category:detail[2],
      artist_work_id:info.id,
      page_category:"Vault"
    })

  }

  

    return(
      <>
        {loading && <Spinner/>}
        {/* <div className="row"> */}
        <div className = {classes.lightboxdetail__container}>
        
        <div className={`row`}>
          <div className={`${classes.lightboxdetail__header} col-md-12`}>
            {
              LightboxStatus 
              ? 
                <h5>This vault has no content</h5>
                : ! loading 
                ?
                  <>
                  <h5>{Lightboxname} {LightboxdestailCount}</h5> 
                  < button className = {`${classes.lightboxdetail__share}`}
                    onClick = {() => showShareModal()}>
                    <h6>share</h6>
                  </button>
                  </>
              : ""
            }
            </div>
          </div>
        </div>
      <div className={classes.lightboxdetail__wrapper}>
      
        <ul className={`siblingIMGContainer ${classes.lightboxdetail}`}>

          { LightboxAPIContent.map((val, index) => (
              
                <li key={index} className={`siblingImgH ${classes.mediaAssetItem}`}>
              
                    { val[0].ext ===  "mp4"
                    ?
                    <>
                      <img className={classes.lightboxdetail__videoPlayIcon} src={videoPlayIcon} alt="" onClick={ () => showCarousel(val, index)}/>
                      <video height='80%' width='100%' playsInline onClick={ () => showCarousel(val, index)}>
                        <source src={`${val[0].path}#t=9`} type="video/mp4" />
                      </video>
                  
                      <button className={classes.lightboxdetail__deleteBtn}
                          onClick = {() => deleteAddMediaAsset(val[0].id)}>
                        <DeleteIcon />
                      </button>
                      <div className={classes.lightboxdetail__imgTitle}>
                        <p>
                          <span>{val[0].first_name} </span>
                          <span>{val[0].last_name}</span>
                          <span>{`, ${val[0].category_name} `}</span> 
                        </p>
                      </div>
                    
                    </>
                    :
                      <>
                       
                      <div className = {classes.lightboxdetail__img}
                          style = {{
                            backgroundImage: 'url(' + val[0].l_path + ')'
                          }}
                          onClick={ () => showCarousel(val, index)}>
                      </div>
                        <button className={classes.lightboxdetail__deleteBtn}
                            onClick = {() => deleteAddMediaAsset(val[0].id)}>
                          <DeleteIcon />                                         
                        </button>
                        <div className={classes.lightboxdetail__imgTitle}>
                          <p>
                            <span>{val[0].first_name} </span>
                            <span>{val[0].last_name}</span>
                            <span>{`, ${val[0].category_name} `}</span> 
                          </p>
                        </div>
                     
                      </>
                    }
                </li>
              ))
          }
        </ul>
      </div>

        {lightboxmodalIsOpen && 
        <CreateUpdateModal open={showLightboxModal} close={hideLightboxModal}  />
        } 

        {sharemodalIsOpen && 
        <ShareModal open={showShareModal} close={hideShareModal}  LightboxShareUUID={LightboxShareUUID}/>
        }

        {modalIsOpen && 
        <Modal open={showModal} close={hideModal} photos={MediaAssets} photoItem={sendMediaAssets} photosDetails={imgDetails} onChangeCallback={onPhotoChange}/>
        } 

        {confirmDelete &&
          <ConfirmModal open={showConfirmDeleteModalIsOpen} close={hideConfirmDeleteModalIsOpen} mediaAssetId={imgDelete} lightboxesId={lightboxes_id} dellIMg={dellIMg} />
        }

      </>
    )
}

export default LightboxDetail;