import React, {useState, useEffect} from 'react';
import classes from './Back.module.scss';
import { useHistory, useLocation, Link } from 'react-router-dom';


const BackBtn = () => {
    let history = useHistory();
    
    let url = useLocation();

    const [isCategories, setIsCategories] =  useState(1);
    let currentUrl = window.location.href;
    let pathDirectories = window.location.pathname.split('/')
    const [lightboxPrevious, setLightboxPrevious] =  useState('/categories');

    const segmentStages = {
        CATEGORY_LISTING:1,
        CATEGORY_PHOTO_USERS:2,
        CATEGORY_PHOTO_USER:3
    }
 
    function checkCurrentUrl(url) {

        let segment = pathDirectories.length - 1

        switch (segment) {
            case segmentStages.CATEGORY_LISTING:
                setIsCategories(segmentStages.CATEGORY_LISTING)
                break;
            case segmentStages.CATEGORY_PHOTO_USERS:
                setIsCategories(segmentStages.CATEGORY_PHOTO_USERS)
                break;
            case segmentStages.CATEGORY_PHOTO_USER:
                setIsCategories(segmentStages.CATEGORY_PHOTO_USER)
                break;
            default:
                break;
        }
       
    }

    function setLightboxURL(){
        setLightboxPrevious(window.location.pathname);
    }

    useEffect(()=>{
        pathDirectories = window.location.pathname.split('/')
        currentUrl = window.location.href;
        checkCurrentUrl(currentUrl)
    }, [url])

        return (
            <div className={classes.Back}>
                
                { (url.pathname !== '/' && url.pathname !== '/login') && ! url.pathname.includes("lightbox/") && url.pathname !== '/forgotten-password' && url.pathname !== '/reset-password/'
                ?
                    <div className={`row`}>
                        <div className={`col-md-12 ${classes.Back__Container}`}>

                            {(isCategories === segmentStages.CATEGORY_LISTING && pathDirectories[1] === 'categories')
                                ? 
                                    <Link className={classes.Back__Link}
                                        to={{
                                            state:  {userType: url.state.userType } 
                                        }}
                                        onClick={ ()=> history.push('/') }>
                                                <h5 className={classes.Back__LinkCopy}>Back</h5>
                                    </Link> 
                                : (isCategories === segmentStages.CATEGORY_LISTING && pathDirectories[1] === 'lightboxes') ?
                                    <Link className={classes.Back__Link}
                                        to={{
                                            state:  {userType: url.state.userType } 
                                        }}
                                        onClick={() => history.push(lightboxPrevious)}>
                                            <h5 className={classes.Back__LinkCopy}>Back</h5>
                                    </Link> 

                                : (isCategories === segmentStages.CATEGORY_PHOTO_USERS && pathDirectories[1] === 'categories') ?
                                    <Link className={classes.Back__Link}
                                        to={{
                                            state:  {userType: url.state.userType } 
                                        }}
                                        onClick={() => history.push('/categories')}>
                                            <h5 className={classes.Back__LinkCopy}>Back</h5>
                                    </Link> 

                                : (isCategories === segmentStages.CATEGORY_PHOTO_USERS && pathDirectories[1] === 'lightboxes') ?
                                <Link className={classes.Back__Link}
                                    to={{
                                        state:  {userType: url.state.userType } 
                                    }}
                                    onClick={() => history.push('/lightboxes')}>
                                        <h5 className={classes.Back__LinkCopy}>Back</h5>
                                </Link> 

                                : (isCategories === segmentStages.CATEGORY_PHOTO_USER) ? 
                                    <Link className={classes.Back__Link}
                                        to={{
                                            state:  {userType: url.state.userType } 
                                        }}
                                        onClick={() => history.push('/categories/'+pathDirectories[isCategories-1]) }>
                                            <h5 className={classes.Back__LinkCopy}>Back</h5>
                                    </Link> 
                                : <Link className={classes.Back__Link}
                                        to={{
                                            state:  {userType: url.state.userType } 
                                        }}
                                       >
                                    <h5 className={classes.Back__LinkCopy}>Back</h5>
                                </Link> 
                            }
                            {/* <Link to={{
                                pathname: "/lightboxes",
                                state: { userType: url.state.userType }
                                }} className={classes.lightboxes__link}
                                onClick={() => setLightboxURL()}
                                ><h6 className={classes.lightboxes__title}>my vault</h6></Link> */}
                            
                        </div>
                    </div>
                    
                : ""}

                </div>
            )
            
}
export default BackBtn;